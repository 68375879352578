import { Space, Col, Row, Checkbox } from 'antd';
import { Content, content_select_items } from '@shared/models/Content';
import { TitleTaggedComponent } from '@shared/components/TitleTaggedComponent';

export interface ContentSetting {
  contents: Content[];
}

export interface ContentSelectProps {
  form: ContentSetting;
  updateForm(form: ContentSetting): void;
}
export function ContentSelect({ form, updateForm }: ContentSelectProps) {
  return (
    <Space size={16} direction='vertical'>
      <Space size={[0, 8]} direction='vertical' style={{ width: '100%' }}>
        <TitleTaggedComponent title='地図表示項目' direction='vertical'>
          <Checkbox.Group
            value={form.contents}
            onChange={(checked_value) => updateForm({ ...form, contents: checked_value as Content[] })}
          >
            <Row>
              {content_select_items.map((dcsi) => {
                return (
                  <Col span={12} key={dcsi.value}>
                    <Checkbox value={dcsi.value}>{dcsi.label}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </TitleTaggedComponent>
      </Space>
    </Space>
  );
}
