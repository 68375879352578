export interface CircleIconProps extends React.SVGProps<SVGSVGElement> {
  size: number;
  opacity: number;
  color: string;
}

export function CircleIcon({ color, size, opacity, ...remain }: CircleIconProps) {
  // .svgファイルでは変数を受け取れないため.tsxファイル内で定義
  // const { size, opacity, color = 'currentColor', style } = props;

  return (
    <svg {...remain} width={size} height={size} viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='50' cy='50' r='45' fill={color} opacity={opacity} />
    </svg>
  );
}
