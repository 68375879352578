/*** エンティティ ***/

import { sendAxios } from 'api/base/sendAxios';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
import { ISO8601 } from '@shared/models/ISO8601';
import getClient from './base';
import { Status } from '@shared/models/Status';
import { addFormData } from '@shared/functions';
import { RcFile } from 'antd/es/upload';

export interface Map {
  map_id: number;
  map_name: string;
  map_description?: string;
  is_open: boolean;
  is_facility: boolean;
  parent_map_id: number | null;
  facility_name: string;
  status: Status;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMap = (data: any): data is Map => typeof data?.map_id === 'number';

export interface MapCamera {
  map_id: number;
  camera_id: number;
  facility_position_x: number;
  facility_position_y: number;
  camera_name: string;
  camera_address: string;
  camera_description: string;
  thumnail_path: string;
  latitude: number;
  longitude: number;
  top: string;
  left: string;
  application_version: string;
  camera_type: string;
  app_param: string;
  is_open: boolean;
  status: Status;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

// const sample_map1: Map = {
//   map_id: '00000001',
//   map_name: '西武渋谷店',
//   map_description: '西武渋谷店',
//   is_open: true,
//   is_facility: false,
//   parent_map_id: null,
//   facility_name: '渋谷施設1',
//   status: 'active',
//   created_at: toISO8601(Date.now()),
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// const sample_map2: Map = {
//   map_id: '00000002',
//   map_name: '渋谷区',
//   map_description: '渋谷区100台プロジェクト用',
//   is_open: false,
//   is_facility: true,
//   parent_map_id: null,
//   facility_name: '',
//   status: 'active',
//   created_at: toISO8601(Date.now()), // Sample UNIX timestamp
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// const sample_map3: Map = {
//   map_id: '00000003',
//   map_name: '西武渋谷店',
//   map_description: '西武渋谷店',
//   is_open: false,
//   is_facility: true,
//   parent_map_id: '00000001',
//   facility_name: '西武渋谷店1F',
//   status: 'active',
//   created_at: toISO8601(Date.now()), // Sample UNIX timestamp
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// const sample_map4: Map = {
//   map_id: '00000004',
//   map_name: '西武渋谷店',
//   map_description: '西武渋谷店',
//   is_open: false,
//   is_facility: true,
//   parent_map_id: '00000001',
//   facility_name: '西武渋谷店2F',
//   status: 'active',
//   created_at: toISO8601(Date.now()), // Sample UNIX timestamp
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// function getSampleMap(parent_id?: string) {
//   if (typeof parent_id === 'string') {
//     return [sample_map3, sample_map4];
//   }
//   return [sample_map1, sample_map2];
// }

/*** [GET] /maps/{map_id} ***/
export interface RequestMapsIdGet extends CommonAPIRequestType {
  map_id: number;
}

export const mapsIdGetAPI = (params: RequestMapsIdGet) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `maps/${params.map_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Map>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: getSampleMap(params.parent_id),
  });
};

/*** [GET] /maps ***/
export interface RequestMapsGet extends CommonAPIRequestType {
  parent_map_id?: number;
  only_area?: boolean;
}

export const mapsGetAPI = (params: RequestMapsGet) => {
  // const {} = toAPIRequestParams(params);
  const { parent_map_id, only_area } = params;
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `maps`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = { parent_map_id, only_area };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Map[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: getSampleMap(params.parent_id),
  });
};

/*** [GET] /maps/image ***/
export interface RequestMapsImageGet extends CommonAPIRequestType {
  map_id: number;
}

export const mapsImageGetAPI = (params: RequestMapsImageGet) => {
  // const {} = toAPIRequestParams(params);
  const { map_id } = params;
  // クライアントを定義
  const axios = getClient({ responseType: 'blob' });

  // パス・メソッドを定義
  const path = `maps/${map_id}/image`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Blob>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: getSampleMap(params.parent_id),
  });
};

/*** [POST] /maps ***/
export interface RequestFacilityMapsPost extends CommonAPIRequestType {
  parent_map_id: number;
  facility_name: string;
  facility_image?: RcFile;
}

export const facilityMapsPostAPI = (params: RequestFacilityMapsPost) => {
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'multipart/form-data' } });

  // パス・メソッドを定義
  const path = `maps/facility`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    addFormData({ form, key, value });
  }

  // 送信
  return sendAxios<Map>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: ['00000001', '00000002', '00000003', '00000005'],
  });
};

/*** [PUT] /maps/{map_id} ***/
export interface RequestFacilityMapsIdPut extends CommonAPIRequestType {
  map_id: number;
  facility_name: string;
  facility_image?: RcFile;
}

export const facilityMapsIdPutAPI = (params: RequestFacilityMapsIdPut) => {
  const { facility_name, facility_image } = params;
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'multipart/form-data' } });

  // パス・メソッドを定義
  const path = `maps/facility/${params.map_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries({ facility_name, facility_image })) {
    addFormData({ form, key, value });
  }

  // 送信
  return sendAxios<Map>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: ['00000001', '00000002', '00000003', '00000005'],
  });
};

/*** [DELETE] maps/{map_id} ***/
export interface RequestFacilityMapsIdDelete {
  map_id: number;
}

export const facilityMapsIdDeleteAPI = (params: RequestFacilityMapsIdDelete) => {
  const { map_id } = params;
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `maps/${map_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<Notification>({
    axios,
    path,
    query,
    form,
    method,
    // sample_notification_1
  });
};

/*** [GET] /maps/{map_id}/cameras ***/
export interface RequestMapsCamerasGet extends CommonAPIRequestType {
  map_id?: number;
}

export const mapsCamerasGetAPI = (params: RequestMapsCamerasGet) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `maps/${params.map_id}/cameras`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = { map_id: params.map_id };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<MapCamera[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: getSampleMap(params.parent_id),
  });
};

/*** [POST] /facilities/{map_id}/cameras ***/
export interface RequestMapsCamerasPost extends CommonAPIRequestType {
  map_id: number;
  camera_id: number;
  camera_name: string;
  facility_position_x: number;
  facility_position_y: number;
}

export const mapsCamerasPostAPI = (params: RequestMapsCamerasPost) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `facilities/${params.map_id}/cameras`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<Map>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: ['00000001', '00000002', '00000003', '00000005'],
  });
};

/*** [DELETE] maps/cameras ***/
export interface RequestMapsCamerasIdDelete {
  map_id: number;
  camera_id: number;
}

export const mapsCamerasIdDeleteAPI = (params: RequestMapsCamerasIdDelete) => {
  const { map_id } = params;
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `maps/${map_id}/cameras/${params.camera_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<Notification>({
    axios,
    path,
    query,
    form,
    method,
    // sample_notification_1
  });
};
