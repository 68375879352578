// カスタムマーカーアイコンの画像ファイルをインポート;
import { Camera, CameraWithMeta } from 'api/cameras';
import { Map, MapCamera, mapsImageGetAPI } from 'api/maps';
import { Space } from 'antd';
import Title from 'antd/es/typography/Title';
import { CameraMarkerIcon } from 'components/FacilityIcons';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { RcFile } from 'antd/es/upload';
import { useCallback, useEffect, useState } from 'react';
import { useElementSize } from 'hooks/useElementSize/useElementSize';

export interface EditFacilityMapProps {
  map?: Map;
  floor_cameras?: CameraWithMeta[];
  setFloorsCameras: React.Dispatch<React.SetStateAction<MapCamera[] | undefined>>;
  facility_img?: RcFile;
  selected_camera?: Camera;
}

export function EditFacilityMap({
  map,
  floor_cameras,
  setFloorsCameras,
  facility_img,
  selected_camera,
}: EditFacilityMapProps) {
  const { ref: imageRef, width, height } = useElementSize<HTMLImageElement>();
  const [img_url, setImgUrl] = useState<string>();
  const uploaded_img = facility_img ? window.URL.createObjectURL(facility_img) : undefined;

  useEffect(() => {
    async function loadImage() {
      if (map?.map_id === undefined) {
        setImgUrl(undefined);
        return;
      }
      const res = await mapsImageGetAPI({ map_id: map.map_id });
      if (res.status === 200) {
        const url = window.URL || window.webkitURL;
        const img_url = url.createObjectURL(res.data);
        setImgUrl(img_url);
      } else {
        setImgUrl(undefined);
      }
    }
    loadImage();
  }, [map?.map_id]);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative', overflow: 'auto' }}>
      <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
        {uploaded_img ? (
          <img ref={imageRef} style={{ width: '100%', height: '100%' }} src={uploaded_img} />
        ) : img_url ? (
          <img ref={imageRef} style={{ width: '100%', height: '100%' }} src={img_url} />
        ) : null}
        {floor_cameras?.map((floor_camera) => {
          return (
            <IconComponent
              key={floor_camera.camera_id}
              floor_camera={floor_camera}
              selected_camera={selected_camera}
              setFloorsCameras={setFloorsCameras}
              width={width}
              height={height}
            />
          );
        })}
      </div>
    </div>
  );
}

function pxToPercent(size: number, px: number, icon_size = 40) {
  let percent = ((px + icon_size / 2) * 100) / size;
  // 0 <= percent <= 100の範囲に収める
  percent = Math.max(0, Math.min(100, percent));
  const scale = 2;
  // 小数第{scale}位で四捨五入
  percent = Math.round(percent * 10 ** scale) / 10 ** scale;
  return percent;
}

function percentToPx(size: number, percent: number, icon_size = 40) {
  return (size * percent) / 100 - icon_size / 2;
}
interface IconComponentProps {
  floor_camera: Camera;
  selected_camera?: Camera;
  setFloorsCameras: React.Dispatch<React.SetStateAction<MapCamera[] | undefined>>;
  width?: number;
  height?: number;
}
function IconComponent({ floor_camera, selected_camera, width, height, setFloorsCameras }: IconComponentProps) {
  const { camera_name, facility_position_x = 0, facility_position_y = 0 } = floor_camera;
  const selected: boolean = selected_camera?.camera_id === floor_camera.camera_id;
  // カメラ位置(px座標)
  const [vector_px, setVectorPx] = useState<{ x: number; y: number } | null>({
    x: facility_position_x,
    y: facility_position_y,
  });

  const loadVector = useCallback(() => {
    if (!width || !height) {
      setVectorPx(null);
      return;
    }
    const x = percentToPx(width, facility_position_x);
    const y = percentToPx(height, facility_position_y);
    setVectorPx({ x, y });
  }, [width, height, facility_position_x, facility_position_y]);

  useEffect(() => {
    loadVector();
  }, [loadVector]);

  // カメラ移動後の場所算出
  const handleStop = (_e: DraggableEvent, dd: DraggableData) => {
    if (typeof width !== 'number' || typeof height !== 'number') return;
    // 移動後の%を算出、20pxはアイコンサイズ
    const { lastX: x_px, lastY: y_px } = dd;
    // カメラ座標(%)
    const x_percent = pxToPercent(width, dd.lastX);
    const y_percent = pxToPercent(height, dd.lastY);
    setVectorPx({ x: x_px, y: y_px });
    setFloorsCameras((prevFloorCameras) => {
      if (!selected_camera || !prevFloorCameras) return prevFloorCameras;
      return prevFloorCameras.map((camera) => {
        if (camera?.camera_id === selected_camera?.camera_id) {
          // 選択されたカメラの座標を更新
          return { ...camera, facility_position_x: x_percent, facility_position_y: y_percent };
        }
        return camera;
      });
    });
  };

  if (!vector_px) return null;
  return (
    <Space>
      {vector_px.x && vector_px.y && (
        <Draggable
          disabled={!selected}
          // positionを同期させる
          position={{ x: vector_px.x, y: vector_px.y }}
          onStop={handleStop}
        >
          <CameraMarkerIcon
            camera_id={floor_camera.camera_id}
            selected={selected}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              cursor: 'pointer',
              zIndex: 10,
              width: 40,
              height: 40,
            }}
            tooltip_content={
              <Space direction='vertical'>
                <Title level={5} style={{ margin: 0 }}>
                  {camera_name}
                </Title>
              </Space>
            }
          />
        </Draggable>
      )}
    </Space>
  );
}
