import { useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './index.css';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

export type CustomZoomControlProps = {
  zoom_level: number;
  device_type: string;
};

const CustomZoomControl = (props: CustomZoomControlProps) => {
  const map = useMap();

  // ズームインボタンのクリックイベントハンドラ
  const handleZoomIn = () => {
    if (props.zoom_level < 19) {
      map.zoomIn();
    }
  };

  // ズームアウトボタンのクリックイベントハンドラ
  const handleZoomOut = () => {
    if (props.zoom_level > 1) {
      map.zoomOut();
    }
  };

  // カスタムズームコントロールのUIを描画
  return (
    <div
      className='custom-zoom-control'
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        bottom: props.device_type == 'sp' ? '80px' : '10px',
        left: '10px',
        zIndex: 1000,
      }}
    >
      <PlusSquareOutlined
        onClick={handleZoomIn}
        className='zoom-icon'
        style={{ fontSize: '32px', backgroundColor: 'white' }}
      />
      <MinusSquareOutlined
        onClick={handleZoomOut}
        className='zoom-icon'
        style={{ fontSize: '32px', backgroundColor: 'white' }}
      />
    </div>
  );
};

export default CustomZoomControl;
