import { SelectItem } from './SelectItem';

export type Age = 'baby' | 'child' | 'adult' | 'middle' | 'elderly';

export const all_ages: Age[] = ['baby', 'child', 'adult', 'middle', 'elderly'];

// Age 型の値に該当するかどうかをチェックする関数
export const isAge = (value: string): value is Age => {
  return value === 'baby' || value === 'child' || value === 'adult' || value === 'middle' || value === 'elderly';
};

// Age 型に変換する関数
export const change_ages = (params?: string) => {
  const ages = params?.split(', ');
  const result: Age[] = ages ? ages.filter(isAge) : [];
  return result;
};

// Age[]をバックエンドに対応するように結合する
export const joinAges = (ages?: Age[]) => {
  if (!ages) return undefined;
  return ages.join(', ');
};

export const hasAllAges = (generations: Age[]) => {
  const unique_generations = new Set(generations);
  return (
    unique_generations.has('baby') &&
    unique_generations.has('child') &&
    unique_generations.has('adult') &&
    unique_generations.has('middle') &&
    unique_generations.has('elderly')
  );
};

export const ageToJP = (age: Age) => {
  switch (age) {
    case 'baby':
      return '幼児 (0-9)';
    case 'child':
      return '子供 (10-19)';
    case 'adult':
      return '青年 (20-39)';
    case 'middle':
      return '中年 (40-64)';
    case 'elderly':
      return '高齢者 (65-)';
    default:
      return '';
  }
};

export const age_select_items: SelectItem<Age>[] = [
  { value: 'baby', label: ageToJP('baby') },
  { value: 'child', label: ageToJP('child') },
  { value: 'adult', label: ageToJP('adult') },
  { value: 'middle', label: ageToJP('middle') },
  { value: 'elderly', label: ageToJP('elderly') },
];
