import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from '@shared/functions';
import { Map, mapsGetAPI } from 'api/maps';

/**
 * マップ一覧を取得するcustom hooks
 */
export const useMaps = (initial_parent_id?: number, only_area?: boolean) => {
  const [maps, setMaps] = useState<Map[] | undefined>(undefined);
  const maps_record: Record<string, Map> = useMemo(() => {
    return maps ? keyBy(maps, (m) => String(m.map_id)) : {};
  }, [maps]);
  const loadMaps = useCallback(
    async (parent_id?: number) => {
      setMaps(undefined);
      const res = await mapsGetAPI({ parent_map_id: parent_id ?? initial_parent_id, only_area: only_area ?? false });
      let new_maps: Map[] = [];
      if (res.status === 200) {
        new_maps = res.data;
      }
      setMaps(new_maps);
    },
    [initial_parent_id, only_area],
  );

  useEffect(() => {
    loadMaps(initial_parent_id);
  }, [loadMaps, initial_parent_id]);

  return useMemo(() => {
    return {
      maps,
      maps_record,
      setMaps,
      loadMaps,
    };
  }, [maps, maps_record, setMaps, loadMaps]);
};
