// カスタムマーカーアイコンの画像ファイルをインポート;
import {
  Map,
  MapCamera,
  facilityMapsIdDeleteAPI,
  facilityMapsPostAPI,
  facilityMapsIdPutAPI,
  mapsCamerasPostAPI,
  mapsCamerasIdDeleteAPI,
} from 'api/maps';
import { Button, Col, Divider, Input, Row, Select, Space, Typography, message } from 'antd';
import Upload, { RcFile } from 'antd/es/upload';
import { DeleteOutlined, LeftOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useMaps } from 'hooks/useMaps/useMaps';
import history from '@shared/browserHistory';
import { UpdateObj, useObj } from '@shared/hooks/useObj/useObj';
import Title from 'antd/es/typography/Title';

interface EditForm {
  map?: Map;
  camera_name: string;
  facility_name: string;
  is_new_facility: boolean;
  step: 1 | 2;
}

export interface FacilityInfo {
  selected_map?: Map;
  selected_facility_map?: Map;
  selected_camera?: MapCamera;
  facility_img?: RcFile;
}

export interface EditSideMenuProps {
  map_cameras?: MapCamera[];
  map_cameras_record: Record<string, MapCamera>;
  floor_cameras?: MapCamera[];
  floor_cameras_record: Record<string, MapCamera>;
  setFloorsCameras: React.Dispatch<React.SetStateAction<MapCamera[] | undefined>>;
  facility_info: FacilityInfo;
  updateFacilityInfo: UpdateObj<FacilityInfo>;
}

export function EditSideMenu({
  map_cameras,
  map_cameras_record,
  floor_cameras,
  floor_cameras_record,
  setFloorsCameras,
  facility_info,
  updateFacilityInfo,
}: EditSideMenuProps) {
  const { obj: form, updateObj: updateForm } = useObj<EditForm>({
    camera_name: '',
    facility_name: '',
    is_new_facility: false,
    step: 1,
  });
  const { step } = form;
  const { map } = form;
  const { maps, maps_record } = useMaps(undefined, true);
  const { maps: facility_maps, maps_record: facility_maps_record, loadMaps: loadFacilityMaps } = useMaps(map?.map_id);

  useEffect(() => {
    if (typeof facility_info.selected_facility_map?.facility_name === 'string') {
      updateForm({ facility_name: facility_info.selected_facility_map?.facility_name });
    }
  }, [facility_info.selected_facility_map, updateForm]);

  useEffect(() => {
    updateForm({ map: maps?.[0] });
    updateFacilityInfo({ selected_map: maps?.[0] });
  }, [maps, updateForm, updateFacilityInfo]);

  useEffect(() => {
    updateFacilityInfo({ selected_facility_map: facility_maps?.[0] });
  }, [updateFacilityInfo, facility_maps]);
  return (
    <div
      style={{
        width: '200px',
        borderRight: '1px solid #808080',
        padding: '10px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Space>
        {step === 1 ? (
          <FacilitySettingPanel
            form={form}
            updateForm={updateForm}
            facility_info={facility_info}
            updateFacilityInfo={updateFacilityInfo}
            maps={maps}
            maps_record={maps_record}
            facility_maps={facility_maps}
            facility_maps_record={facility_maps_record}
            loadFacilityMaps={loadFacilityMaps}
          />
        ) : step === 2 ? (
          <CameraSettingPanel
            form={form}
            updateForm={updateForm}
            facility_info={facility_info}
            updateFacilityInfo={updateFacilityInfo}
            map_cameras={map_cameras}
            map_cameras_record={map_cameras_record}
            floor_cameras={floor_cameras}
            floor_cameras_record={floor_cameras_record}
            setFloorsCameras={setFloorsCameras}
          />
        ) : null}
      </Space>
    </div>
  );
}

/**
 * 施設設定用のパネル
 */
interface FacilitySettingPanelProps {
  form: EditForm;
  updateForm: UpdateObj<EditForm>;
  facility_info: FacilityInfo;
  updateFacilityInfo: UpdateObj<FacilityInfo>;
  maps?: Map[];
  maps_record: Record<string, Map>;
  facility_maps?: Map[];
  facility_maps_record: Record<string, Map>;
  loadFacilityMaps(parent_id?: number): Promise<void>;
}
function FacilitySettingPanel({
  form,
  updateForm,
  facility_info,
  updateFacilityInfo,
  maps,
  maps_record,
  facility_maps,
  facility_maps_record,
  loadFacilityMaps,
}: FacilitySettingPanelProps) {
  const { map, facility_name } = form;
  const [selectFacilityOpen, setSelectFacilityOpen] = useState<boolean>(false);

  // マップの変更
  const onMapChange = (new_map_id: number) => {
    updateForm({ map: maps_record[new_map_id] });
    loadFacilityMaps(new_map_id);
  };

  // 施設の変更
  const onFacilityChange = (new_facility_map_id: number | string) => {
    updateFacilityInfo({ selected_facility_map: facility_maps_record[new_facility_map_id] });
  };

  // 施設新規作成
  const onFacilityCreate = () => {
    updateFacilityInfo({ selected_facility_map: undefined });
    updateForm({ facility_name: '', is_new_facility: true });
  };

  // 施設名変更
  const onFacilityNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateForm({ facility_name: e.currentTarget.value });
  };

  // 画像アップロード
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('アップロード画像はJPGかPNG形式のみになります。');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('アップロード画像は10MB以下になります。!');
    }
    updateFacilityInfo({ facility_img: file });
    return false;
  };

  // カメラ設定への遷移
  const goCameraSettingPanel = () => {
    updateForm({ step: 2 });
  };

  // 施設削除処理
  const onFacilityDelete = async () => {
    // [todo] アラートメッセージの表示
    const map_id = facility_info.selected_facility_map?.map_id;
    if (typeof map_id !== 'number') return;
    const res = await facilityMapsIdDeleteAPI({
      map_id,
    });
    if (res.status === 200) {
      history.push(`/facility/top/${map?.map_id}`);
    }
  };

  // トップページへの遷移
  const backTopPage = () => {
    history.push(`/facility/top/${map?.map_id}`);
  };
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title level={4}>施設設定</Title>
      </Col>
      <Col span={24}>
        <Typography.Text strong>施設</Typography.Text>
        <Select
          style={{ width: '100%' }}
          value={map?.map_id}
          onChange={onMapChange}
          options={maps?.map((map) => {
            return { value: map.map_id, label: map.map_name };
          })}
        />
      </Col>

      <Col span={24}>
        <Typography.Text strong>施設詳細</Typography.Text>
        <Select
          style={{ width: '100%' }}
          value={
            facility_info.selected_facility_map?.map_id
              ? facility_info.selected_facility_map?.facility_name
              : '＋新規作成'
          }
          open={selectFacilityOpen}
          onChange={onFacilityChange}
          onClick={() => setSelectFacilityOpen(!selectFacilityOpen)}
          onDropdownVisibleChange={() => ''}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{
                  padding: '4px 8px',
                  cursor: 'pointer',
                  color: '#1890ff',
                  width: '100%',
                }}
                onClick={onFacilityCreate}
              >
                ＋新規作成
              </div>
            </>
          )}
          options={facility_maps?.map((map) => {
            return { value: map.map_id, label: map.facility_name };
          })}
        ></Select>
      </Col>
      <Col span={24}>
        <Typography.Text strong>施設詳細名</Typography.Text>
        <Input placeholder='施設詳細名の入力' value={facility_name} onChange={onFacilityNameChange} />
      </Col>
      <Col span={24}>
        <Typography.Text strong>施設詳細画像</Typography.Text>
        <Upload
          beforeUpload={(file) => beforeUpload(file)}
          listType='picture'
          maxCount={1}
          onRemove={() => updateFacilityInfo({ facility_img: undefined })}
        >
          <Button icon={<UploadOutlined />}>ファイル選択</Button>
        </Upload>
      </Col>
      <Col span={24}>
        <Button
          onClick={goCameraSettingPanel}
          type='primary'
          shape='round'
          style={{ width: '100%' }}
          disabled={!facility_name}
        >
          <SettingOutlined /> カメラ設定に移動
        </Button>
      </Col>
      <Col span={24}>
        <Button
          onClick={backTopPage}
          type='primary'
          shape='round'
          style={{ backgroundColor: '#808080', width: '100%' }}
        >
          <LeftOutlined /> 施設表示に戻る
        </Button>
      </Col>
      <Col span={24}>
        <Button
          onClick={onFacilityDelete}
          type='primary'
          shape='round'
          style={{ backgroundColor: '#ff4d4f', width: '100%' }}
          disabled={!facility_info.selected_facility_map?.map_id}
        >
          <DeleteOutlined /> 施設情報の削除
        </Button>
      </Col>
    </Row>
  );
}

interface CameraSettingPanelProps {
  form: EditForm;
  updateForm: UpdateObj<EditForm>;
  facility_info: FacilityInfo;
  updateFacilityInfo: UpdateObj<FacilityInfo>;
  map_cameras: MapCamera[] | undefined;
  map_cameras_record: Record<string, MapCamera>;
  floor_cameras: MapCamera[] | undefined;
  floor_cameras_record: Record<string, MapCamera>;
  setFloorsCameras: React.Dispatch<React.SetStateAction<MapCamera[] | undefined>>;
}

function CameraSettingPanel({
  form,
  updateForm,
  facility_info,
  updateFacilityInfo,
  map_cameras,
  map_cameras_record,
  floor_cameras,
  setFloorsCameras,
}: CameraSettingPanelProps) {
  const { map, camera_name } = form;
  const [selected_camera, setSelectedCamera] = useState<MapCamera>();
  // カメラ変更
  const onCameraChange = (new_camera_id: number | string) => {
    updateFacilityInfo({ selected_camera: map_cameras_record[new_camera_id] });
    setSelectedCamera(map_cameras_record[new_camera_id]);
    updateForm({ camera_name: map_cameras_record[new_camera_id].camera_name });
  };

  // カメラ追加
  const onAddCamera = () => {
    if (selected_camera) {
      setFloorsCameras((floor_cameras || []).concat(selected_camera));
    }
  };

  // カメラ削除
  const onDeleteCamera = () => {
    if (selected_camera) {
      setFloorsCameras((floor_cameras || []).filter((camera) => camera.camera_id !== selected_camera.camera_id));
    }
  };

  // カメラ名変更
  const onCameraNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCameraName = e.currentTarget.value;
    updateForm({ camera_name: newCameraName });

    // floor_cameras 配列内のカメラ名を変更
    setFloorsCameras((prevFloorCameras) => {
      if (!selected_camera || !prevFloorCameras) return prevFloorCameras;

      return prevFloorCameras.map((camera) => {
        if (camera?.camera_id === selected_camera?.camera_id) {
          // 選択されたカメラのカメラ名を更新
          return { ...camera, camera_name: newCameraName };
        }
        return camera;
      });
    });
  };

  // 施設編集への遷移
  const goFacilitySettingPanel = () => {
    updateForm({ step: 1 });
  };

  // 保存処理
  const onFinish = async () => {
    let map_id = facility_info.selected_facility_map?.map_id;
    // 新規作成
    if (!facility_info.selected_facility_map?.map_id || form.is_new_facility) {
      if (typeof map?.map_id !== 'number') return;
      const res = await facilityMapsPostAPI({
        parent_map_id: map?.map_id,
        facility_name: form.facility_name,
        facility_image: facility_info.facility_img,
      });
      if (res.status === 200) {
        map_id = res.data.map_id;
      }
      // 更新処理
    } else {
      if (typeof facility_info.selected_facility_map?.map_id !== 'number') return;
      const res = await facilityMapsIdPutAPI({
        map_id: facility_info.selected_facility_map?.map_id,
        facility_name: form.facility_name,
        facility_image: facility_info.facility_img,
      });
      if (res.status !== 200) {
        return;
      }
    }

    // マップカメラ登録
    map_cameras?.forEach(async (camera) => {
      if (!map_id) return;
      // カメラの追加・更新
      const floor_camera = floor_cameras?.find((item) => item.camera_id === camera.camera_id);
      if (floor_camera) {
        const map_cameras_res = await mapsCamerasPostAPI({
          map_id: map_id,
          camera_id: camera.camera_id,
          camera_name: floor_camera.camera_name,
          facility_position_x: floor_camera.facility_position_x,
          facility_position_y: floor_camera.facility_position_y,
        });
        if (map_cameras_res.status !== 200) {
          // [todo] アラート
        }
      } else {
        // カメラの削除
        const map_cameras_res = await mapsCamerasIdDeleteAPI({
          map_id: map_id,
          camera_id: camera.camera_id,
        });
        if (map_cameras_res.status !== 200) {
          // [todo] アラート
        }
      }
    });
    history.push(`/facility/top/${map?.map_id}`);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title level={4}>カメラ設定</Title>
      </Col>
      <Col span={24}>
        <Typography.Text strong>カメラ選択</Typography.Text>
        <Select
          style={{ width: '100%' }}
          value={facility_info.selected_camera?.camera_id ?? ''}
          onChange={onCameraChange}
          options={map_cameras?.map((camera) => {
            return { value: camera.camera_id, label: camera.camera_name };
          })}
        />
      </Col>
      <Col span={24} style={{ display: 'flex' }}>
        <Button
          onClick={onAddCamera}
          type='primary'
          shape='round'
          style={{ width: '100%' }}
          disabled={!!floor_cameras?.find((camera) => camera.camera_id === selected_camera?.camera_id)}
        >
          <SettingOutlined /> 追加
        </Button>
        <Button
          onClick={onDeleteCamera}
          type='primary'
          shape='round'
          style={{ backgroundColor: '#ff4d4f', width: '100%' }}
          disabled={!floor_cameras?.find((camera) => camera.camera_id === selected_camera?.camera_id)}
        >
          <SettingOutlined /> 削除
        </Button>
      </Col>
      <Col span={24}>
        <Typography.Text strong>カメラ名</Typography.Text>
        <Input placeholder='カメラ名の入力' value={camera_name} onChange={onCameraNameChange} />
      </Col>
      <Col span={24}>
        <Button onClick={onFinish} type='primary' shape='round' style={{ width: '100%' }}>
          <SettingOutlined /> 保存
        </Button>
      </Col>
      <Col span={24}>
        <Button
          onClick={goFacilitySettingPanel}
          type='primary'
          shape='round'
          style={{ backgroundColor: '#808080', width: '100%' }}
        >
          <LeftOutlined /> 施設設定に戻る
        </Button>
      </Col>
    </Row>
  );
}
