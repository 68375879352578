import { RadioGroupProps, Radio } from 'antd';

export interface RadioInputProps extends RadioGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: { value: any; label: string }[];
}
export function RadioInput(props: RadioInputProps) {
  return (
    <Radio.Group {...props}>
      {props.items.map((item) => (
        <Radio key={item.value} value={item.value}>
          {item.label}
        </Radio>
      ))}
    </Radio.Group>
  );
}
