/*** エンティティ ***/

import { sendAxios } from 'api/base/sendAxios';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
import getClient from './base';

export interface Todo {
  id: number;
  title: string | null;
  done: boolean;
}

/*** [GET] /todos ***/
export interface RequestTodosGet extends CommonAPIRequestType {}

export const todosGetAPI = () => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `todos`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Todo[]>({
    axios,
    path,
    query,
    form,
    method,
  });
};
