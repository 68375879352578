import { Row, Col } from 'antd/es/grid';
import Space from 'antd/es/space';
import Typography from 'antd/es/typography';
import { Camera } from 'api/cameras';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { CameraMarkerIcon, CircleIcon } from 'components/FacilityIcons';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { useCameraMetaIcon } from './useCameraMetaIcon';

/**
 * Facility画面で利用するCameraIconの表示に関する部分
 */
export interface FacilityCameraMetaIconProps {
  camera: Camera;
  selected_camera: Camera | null;
  content_setting: ContentSetting;
  age_setting: AgeSetting;
  period_setting: PeriodSetting;
  circle_color: string;
  onCameraClick(camera: Camera): void;
}
export function FacilityCameraMetaIcon({
  camera,
  selected_camera,
  period_setting,
  age_setting,
  content_setting,
  circle_color,
  onCameraClick,
}: FacilityCameraMetaIconProps) {
  const { camera_name, camera_id, facility_position_y, facility_position_x } = camera;

  const { gradient_option, tooltip_descriptions, content_meta_data, circle_size } = useCameraMetaIcon({
    camera_id,
    period_setting,
    content_setting,
    age_setting,
  });
  return (
    <>
      <CameraMarkerIcon
        camera_id={camera_id}
        selected={selected_camera?.camera_id === camera_id}
        style={{
          position: 'absolute',
          top: `calc(${facility_position_y}% - 40px / 2)`,
          left: `calc(${facility_position_x}% - 40px / 2)`,
          cursor: 'pointer',
          zIndex: 999,
          width: 40,
          height: 40,
        }}
        gradient_option={gradient_option}
        onClick={() => onCameraClick(camera)}
        tooltip_content={
          <Space direction='vertical'>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {camera_name}
            </Typography.Title>
            <Row gutter={[16, 4]} style={{ color: 'black', width: 200 }}>
              {tooltip_descriptions?.map((description) => {
                return (
                  <Col key={description.key} span={24}>
                    {description.label}
                  </Col>
                );
              })}
            </Row>
          </Space>
        }
      />
      {typeof content_meta_data?.human_traffic === 'number' && (
        <CircleIcon
          style={{
            position: 'absolute',
            top: `calc(${facility_position_y}% - ${circle_size}px / 2)`,
            left: `calc(${facility_position_x}% - ${circle_size}px / 2)`,
          }}
          size={circle_size}
          opacity={0.7}
          color={circle_color}
        />
      )}
    </>
  );
}
