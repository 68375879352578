import { Row, Col } from 'antd/es/grid';
import Space from 'antd/es/space';
import Typography from 'antd/es/typography';
import { Camera } from 'api/cameras';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { useCameraMetaIcon } from './useCameraMetaIcon';
import { CameraMarkerLeafletIcon, CircleLeafletIcon } from 'components/LeafletIcons';
import { LatLng } from 'leaflet';

/**
 * Urban画面で利用するCameraIconの表示に関する部分
 */
export interface UrbanCameraMetaIconProps {
  camera: Camera;
  selected_camera: Camera | null;
  content_setting: ContentSetting;
  age_setting: AgeSetting;
  period_setting: PeriodSetting;
  zoom_level: number;
  circle_color: string;
  onCameraClick(camera: Camera): void;
}
export function UrbanCameraMetaIcon({
  camera,
  selected_camera,
  period_setting,
  age_setting,
  content_setting,
  zoom_level,
  circle_color,
  onCameraClick,
}: UrbanCameraMetaIconProps) {
  const { latitude, longitude, camera_name, camera_id } = camera;

  const { gradient_option, tooltip_descriptions, content_meta_data, circle_size } = useCameraMetaIcon({
    camera_id,
    period_setting,
    content_setting,
    age_setting,
    zoom_level,
  });
  const position = new LatLng(latitude, longitude);
  return (
    <Space key={camera_id}>
      <CameraMarkerLeafletIcon
        selected={selected_camera?.camera_id === camera_id}
        size={56}
        position={position}
        gradient_option={gradient_option}
        marker_props={{
          // カメラアイコンがサークルよりも上に来るようにzIndex加算値を大きく設定
          zIndexOffset: 999,
        }}
        onClick={() => onCameraClick(camera)}
        tooltip_content={
          <Space direction='vertical'>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {camera_name}
            </Typography.Title>
            <Row gutter={[16, 4]} style={{ width: 200 }}>
              {tooltip_descriptions.map((description) => {
                return (
                  <Col key={description.key} span={24}>
                    {description.label}
                  </Col>
                );
              })}
            </Row>
          </Space>
        }
      />
      {typeof content_meta_data?.human_traffic === 'number' && (
        <CircleLeafletIcon size={circle_size} opacity={0.7} position={position} color={circle_color} />
      )}
    </Space>
  );
}
