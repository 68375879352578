import { EditFacilityMap } from 'pages/facility/top/EditPage/EditFacilityMap';
import { EditSideMenu, FacilityInfo } from 'pages/facility/top/EditPage/EditSideMenu';
import { useObj } from '@shared/hooks/useObj/useObj';
import { useMapsCameras } from 'hooks/useMapsCameras/useMapsCameras';

export default function TodosTopPage() {
  const { obj: facility_info, updateObj: updateFacilityInfo } = useObj<FacilityInfo>({});
  const { map_cameras, map_cameras_record } = useMapsCameras(facility_info.selected_map?.map_id);
  const {
    map_cameras: floor_cameras,
    map_cameras_record: floor_cameras_record,
    setMapsCameras: setFloorsCameras,
  } = useMapsCameras(facility_info.selected_facility_map?.map_id);

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <EditSideMenu
        map_cameras={map_cameras}
        map_cameras_record={map_cameras_record}
        floor_cameras={floor_cameras}
        floor_cameras_record={floor_cameras_record}
        setFloorsCameras={setFloorsCameras}
        facility_info={facility_info}
        updateFacilityInfo={updateFacilityInfo}
      />
      <EditFacilityMap
        map={facility_info.selected_facility_map}
        floor_cameras={floor_cameras}
        setFloorsCameras={setFloorsCameras}
        facility_img={facility_info.facility_img}
        selected_camera={facility_info.selected_camera}
      />
    </div>
  );
}
