import { useCallback, useEffect, useMemo, useState } from 'react';
import { Countdata, countdataCameraIdGetAPI } from 'api/countdata';
import { Unit } from '@shared/models/Unit';
import { Camera } from 'api/cameras';
import { ISO8601 } from '@shared/models/ISO8601';

/**
 * メトリック一覧を取得するcustom hooks
 */

export const useMetrics = ({
  unit,
  start,
  end,
  line_no,
  camera,
}: {
  unit: Unit;
  start?: ISO8601;
  end?: ISO8601;
  line_no?: number;
  camera: Camera;
}) => {
  const [metrics, setMetrics] = useState<Countdata[] | undefined>(undefined);
  const loadMetrics = useCallback(
    async (args: { unit: Unit; start?: ISO8601; end?: ISO8601; line_no?: number }) => {
      setMetrics(undefined);
      const res = await countdataCameraIdGetAPI({
        camera_id: camera.camera_id,
        start: args.start,
        end: args.end,
        unit: args.unit,
        line_no: args.line_no,
      });
      let metrics: Countdata[] = [];
      if (res.status === 200) {
        metrics = res.data;
      }
      setMetrics(metrics);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [camera],
  );

  useEffect(() => {
    loadMetrics({ unit, start, end, line_no });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit, start, end, camera]);

  return useMemo(() => {
    return {
      metrics,
      setMetrics,
      loadMetrics,
    };
  }, [metrics, setMetrics, loadMetrics]);
};
