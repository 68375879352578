import { useObj } from '@shared/hooks/useObj/useObj';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { FacilityTopHeaderMenu } from './components/FacilityTopHeader';
import { FacilityTopBody } from './components/FacilityTopBody';
import { all_ages } from '@shared/models/Age';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { RouteComponentProps } from 'react-router-dom';
import { useViewportSize } from '@shared/hooks/useViewPortSize/useViewPortSize';
import { user_atom } from 'customJotai/atom';
import { useAtom } from 'jotai';
import { userToPeriodSetting } from 'components/PeriodSettingEngine/functions';

type Props = RouteComponentProps<{ map_id: string }>;

export default function FacilityTopPage(props: Props) {
  const map_id = props.match.params.map_id ? parseInt(props.match.params.map_id) : undefined;
  const [user] = useAtom(user_atom);
  const { device_type } = useViewportSize();
  const { obj: content_setting, updateObj: updateContentSetting } = useObj<ContentSetting>({
    contents: user?.display_content ?? [],
  });
  const { obj: age_setting, updateObj: updateAgeSetting } = useObj<AgeSetting>({
    ages: user?.display_age ?? all_ages,
  });
  //ユーザの情報に基づく地図表示項目を設定
  const { obj: period_setting, updateObj: updatePeriodSetting } = useObj<PeriodSetting>(userToPeriodSetting(user));
  const circle_color = user
    ? typeof user.circle_color === 'object'
      ? user.circle_color.toHexString()
      : user.circle_color
    : 'orange'; //人流カラーピッカーを設定していなければ初期値(オレンジ)を設定

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <FacilityTopHeaderMenu
        period_setting={period_setting}
        updatePeriodSetting={updatePeriodSetting}
        content_setting={content_setting}
        updateContentSetting={updateContentSetting}
        age_setting={age_setting}
        updateAgeSetting={updateAgeSetting}
        device_type={device_type}
      />

      <FacilityTopBody
        map_id={map_id}
        period_setting={period_setting}
        updatePeriodSetting={updatePeriodSetting}
        content_setting={content_setting}
        updateContentSetting={updateContentSetting}
        age_setting={age_setting}
        updateAgeSetting={updateAgeSetting}
        device_type={device_type}
        circle_color={circle_color}
      />
    </div>
  );
}
