import history from '@shared/browserHistory';
import { Col, Row, Typography } from 'antd';
import { usersMailAddressGetAPI } from 'api/users';
import { useSetAtom } from 'jotai';
import { access_token_atom, user_atom } from 'customJotai/atom';
import useMessage from 'antd/es/message/useMessage';
import { CognitoUser2 } from '@shared/models/Cognito';
import { useState } from 'react';
import { CompleteNewPasswordPanel } from '@shared/components/Login/completeNewPasswordPanel';
import { LoginFieldType, LoginPanel } from '@shared/components/Login/loginPanel';
import { cognitoAuth } from 'utils/auth/cognito';

export function LoginPage() {
  const setUser = useSetAtom(user_atom);
  const setAccessToken = useSetAtom(access_token_atom);
  const [messageApi, context] = useMessage({ maxCount: 2 });
  const [sign_in_user, setSignInUser] = useState<CognitoUser2 | null>(null);

  const onBackToLoginPage = () => {
    setSignInUser(null);
  };

  const login = async (values: LoginFieldType) => {
    const { password, email } = values;
    const user_data: CognitoUser2 = await cognitoAuth.signIn({ email, password });
    if (user_data.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setSignInUser(user_data);
      messageApi.info('初期パスワードの変更を行ってください');
    } else if (user_data.challengeName === 'MFA_REQUIRED' || user_data.challengeName === 'SMS_MFA') {
      // ２段階認証処理
      console.log('MFAは現在非対応です');
    } else {
      cognitoAuth.getAccessToken().then(async (access_token) => {
        if (!access_token) return;
        setAccessToken(access_token);
        const mail_address = user_data.attributes['email'];
        if (!mail_address) {
          messageApi.info(`登録されたユーザーのメールアドレスが見つかりません:${mail_address}`);
          return;
        }

        const res = await usersMailAddressGetAPI({ mail_address });
        if (res.status === 200) {
          setUser(res.data);
          if (res.data.home_page == 'urban') {
            history.push('/urban/top');
          } else {
            history.push('/facility/top');
          }
        }
      });
    }
  };

  return (
    <>
      {context}
      <Row
        gutter={[24, 24]}
        style={{
          maxWidth: 300,
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translateY(-40%) translateX(-50%)',
        }}
      >
        <Col span={24}>
          <Typography.Title>IDEA DemoGrid</Typography.Title>
        </Col>
        <Col span={24}>
          {sign_in_user ? (
            <CompleteNewPasswordPanel
              user={sign_in_user}
              onBack={onBackToLoginPage}
              messageApi={messageApi}
              cognitoCompleteNewPassword={cognitoAuth.completeNewPassword}
            />
          ) : (
            <LoginPanel login={login} />
          )}
        </Col>
      </Row>
    </>
  );
}

// {
//   label: 'アカウント作成',
//   key: '2',
//   children: (
//     <Form onFinish={signUp} requiredMark={false} layout='vertical'>
//       <Form.Item<SignUpFieldType>
//         label='ユーザー名'
//         name='username'
//         rules={[{ required: true, message: 'ユーザー名を入力してください' }]}
//       >
//         <Input />
//       </Form.Item>
//       <Form.Item<SignUpFieldType>
//         label='メール'
//         name='email'
//         rules={[
//           { required: true, message: 'メールアドレスを入力してください' },
//           () => ({
//             validator(_, value) {
//               if (isValidEmail(value)) return Promise.resolve();
//               return Promise.reject(new Error('***@**.**の形式を満たしてください'));
//             },
//           }),
//         ]}
//       >
//         <Input />
//       </Form.Item>

//       <Form.Item<SignUpFieldType>
//         label='パスワード'
//         name='password'
//         rules={[
//           {
//             required: true,
//             message: 'パスワードを入力してください',
//           },
//           {
//             min: 8,
//             message: '8文字以上が必要です',
//           },
//           () => ({
//             validator(_, value) {
//               if (hasNumber(value) && hasLowerCase(value)) return Promise.resolve();
//               return Promise.reject(new Error('少なくとも1つの英字と数字を含む必要があります。'));
//             },
//           }),
//         ]}
//       >
//         <Input.Password />
//       </Form.Item>
//       <Form.Item<SignUpFieldType>
//         label='確認用パスワード'
//         name='confirmation_password'
//         dependencies={['password']}
//         rules={[
//           {
//             required: true,
//             message: '確認用パスワードを入力してください',
//           },
//           ({ getFieldValue }) => ({
//             validator(_, value) {
//               if (!value || getFieldValue('password') === value) return Promise.resolve();
//               return Promise.reject(new Error('パスワードと確認用パスワードが一致しません'));
//             },
//           }),
//         ]}
//       >
//         <Input.Password />
//       </Form.Item>
//       <Form.Item>
//         <Button type='primary' shape='round' htmlType='submit'>
//           アカウント作成
//         </Button>
//       </Form.Item>
//     </Form>
//   ),
// },
