import { GlobalOutlined } from '@ant-design/icons';
import { UpdateObj } from '@shared/hooks/useObj/useObj';
import { all_ages } from '@shared/models/Age';
import { hasHumanTraffic } from '@shared/models/Content';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import { AccountSettingSetting } from 'components/AccountSetting';
import { AgeSettingEngine } from 'components/AgeSettingEngine';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { ContentSettingEngine } from 'components/ContentSettingEngine';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { PeriodSettingEngine } from 'components/PeriodSettingEngine';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import history from '@shared/browserHistory';

/**
 * Facility View トップページのヘッダーメニュー
 */
export interface FacilityTopHeaderMenuProps {
  period_setting: PeriodSetting;
  updatePeriodSetting: UpdateObj<PeriodSetting>;
  content_setting: ContentSetting;
  updateContentSetting: UpdateObj<ContentSetting>;
  age_setting: AgeSetting;
  updateAgeSetting: UpdateObj<AgeSetting>;
  device_type: string;
}
export function FacilityTopHeaderMenu({
  period_setting,
  updatePeriodSetting,
  age_setting,
  updateAgeSetting,
  content_setting,
  updateContentSetting,
  device_type,
}: FacilityTopHeaderMenuProps) {
  const customUpdateContentSetting = (form: ContentSetting) => {
    const { contents } = form;
    if (hasHumanTraffic(contents)) {
      updateAgeSetting({
        ages: all_ages,
      });
    }
    updateContentSetting(form);
  };

  // UrbanViewへの遷移
  const onUrbanview = () => {
    history.push(`/urban/top`);
  };

  return (
    <div style={{ width: '100%', height: 60, backgroundColor: '#000' }}>
      <Space style={{ width: '100%', height: '100%' }} direction='vertical'>
        {device_type != 'sp' ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
            <Space>
              <PeriodSettingEngine
                form={period_setting}
                updateForm={updatePeriodSetting}
                input_style={{ maxWidth: 250 }}
              />
              <ContentSettingEngine updateForm={customUpdateContentSetting} form={content_setting} />
              {hasHumanTraffic(content_setting.contents) && (
                <AgeSettingEngine updateForm={updateAgeSetting} form={age_setting} />
              )}
            </Space>
            <Space>
              <Button onClick={onUrbanview} type='primary' shape='round'>
                <GlobalOutlined /> UrbanView
              </Button>
              <AccountSettingSetting />
            </Space>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 10 }}>
            <Space>
              <Button onClick={onUrbanview} type='primary' shape='round'>
                <GlobalOutlined /> UrbanView
              </Button>
              <AccountSettingSetting />
            </Space>
          </div>
        )}
      </Space>
    </div>
  );
}
