import { CameraWithMeta } from 'api/cameras';
import { camera_person_data_record, generatePersonData, AgeGroups, sumAgeGroups } from 'api/countdata';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { CameraMarkerIconProps } from 'components/FacilityIcons/CameraMarkerIcon';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';

// 共通関数

/**
 * ダミーのカメラに対するメタデータを取得する関数
 */
export function getCameraMetaResult(args: {
  camera_meta: CameraWithMeta;
  period_setting?: PeriodSetting;
  age_setting?: AgeSetting;
  use_human_traffic?: boolean;
  use_gender?: boolean;
}) {
  const { camera_meta, period_setting, age_setting, use_human_traffic, use_gender } = args;
  const result: CameraWithMeta = { ...camera_meta };
  // [TODO] ダミーデータをやめる

  let person_data = camera_person_data_record[camera_meta.camera_id];
  if (period_setting) {
    person_data = generatePersonData();
  }
  if (use_human_traffic) {
    const partial_man: Partial<AgeGroups> = {};
    const partial_woman: Partial<AgeGroups> = {};
    age_setting?.ages.forEach((age) => {
      partial_man[age] = person_data.male[age];
      partial_woman[age] = person_data.female[age];
    });
    const total = sumAgeGroups(partial_man) + sumAgeGroups(partial_woman);
    result.human_traffic = {
      total,
    };
  }
  if (use_gender) {
    const man_total = sumAgeGroups(person_data.male);
    const woman_total = sumAgeGroups(person_data.female);
    result.gender = {
      men: man_total,
      women: woman_total,
    };
  }
  return result;
}

/**
 * 地図や画像上のカメラアイコンの外円の直径を計算する関数
 * @param num: 数値
 * @param factor: 任意係数
 * @returns カメラアイコンの外円の直径
 */
export function getCameraCircleSize(num?: number, factor?: number) {
  if (!num) return 0;
  const _factor = factor ?? 7;
  const max_size = 250;
  const min_size = 50;
  const result = _factor * 0.0035 * num;
  return Math.min(Math.max(min_size, result), max_size);
}

/**
 * カメラアイコンで利用するgradientOptionとdescriptionsを取得する
 */
export function getGradientOptionAndDescriptions(args: {
  human_traffic: CameraWithMeta['human_traffic'];
  gender: CameraWithMeta['gender'];
}) {
  const { human_traffic, gender } = args;
  let gradient: CameraMarkerIconProps['gradient_option'] = undefined;
  const descriptions: { key: string; label: string }[] = [];
  if (human_traffic) {
    descriptions.push({
      key: 'human_traffic',
      label: `合計：${human_traffic.total}人`,
    });
  }
  if (gender) {
    const gender_total = gender.men + gender.women;
    const men_percent = (gender.men / gender_total) * 100;
    const women_percent = (gender.women / gender_total) * 100;
    descriptions.push({
      key: 'gender',
      label: `男性：${men_percent.toFixed(0)}% 女性：${women_percent.toFixed(0)}%`,
    });
    gradient = {
      left_percent: men_percent,
    };
  }
  return { gradient, descriptions };
}
