import L from 'leaflet';
import { Marker, MarkerProps } from 'react-leaflet';

export function CircleLeafletIcon(
  props: {
    size: number;
    opacity: number;
    position: L.LatLngExpression;
    color?: string;
  } & MarkerProps,
) {
  // .svgファイルでは変数を受け取れないため.tsxファイル内で定義
  const { size, opacity, position, color = 'currentColor' } = props;
  const svg_markup = `
    <svg width="${size}" height="${size}" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="45" fill="${color}" opacity="${opacity}" />
    </svg>
  `;

  const iconUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg_markup)}`;
  const icon = L.icon({
    iconUrl,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
  });

  return <Marker {...props} position={position} icon={icon} />;
}
