import { toPeriodSettingValue } from '@shared/functions';
import { Space, Input, Popover } from 'antd';
import { PeriodSelect, PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { useMemo } from 'react';

export interface PeriodSettingEngineProps {
  form: PeriodSetting;
  updateForm(form: PeriodSetting): void;
  input_style?: React.CSSProperties;
}
export function PeriodSettingEngine(props: PeriodSettingEngineProps) {
  const { form, updateForm, input_style } = props;
  const period_setting_value = useMemo(() => {
    return toPeriodSettingValue(form);
  }, [form]);

  return (
    <Popover
      content={
        <Space size={[0, 16]} direction='vertical' style={{ width: '100%' }}>
          <PeriodSelect form={form} updateForm={updateForm} />
        </Space>
      }
      trigger='click'
    >
      <Input
        placeholder='クリックして表示期間の選択'
        value={period_setting_value}
        readOnly
        style={{ ...input_style }}
      />
    </Popover>
  );
}
