/*** エンティティ ***/

import { sendAxios } from 'api/base/sendAxios';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
import { ISO8601 } from '@shared/models/ISO8601';
import getClient from './base';
import { Status } from '@shared/models/Status';
import { ViewType } from '@shared/models/ViewType';
import { Unit } from '@shared/models/Unit';
import { Period } from '@shared/models/Period';
import { Role } from '@shared/models/Role';
import { Color } from 'antd/es/color-picker';
import { Content } from '@shared/models/Content';
import { Age } from '@shared/models/Age';

export interface User {
  user_id: number;
  user_name: string;
  mail_address: string;
  home_page: ViewType;
  home_address: [number, number];
  display_unit: Unit;
  display_period: Period;
  start_year: number | null;
  start_day: number | null;
  start_time: number | null;
  start_month: number | null;
  start_date: number | null;
  is_display_content: boolean;
  display_content: Content[] | null;
  display_age: Age[];
  circle_color: string | Color;
  role: Role;
  status: Status;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

// export const sample_user1: User = {
//   user_id: 1,
//   user_name: 'johndoe123',
//   mail_address: 'johndoe@example.com',
//   home_page: 'urban',
//   home_address: '35.65, 139.70',
//   display_unit: 'hour',
//   display_period: 'period',
//   start_day: 1,
//   start_time: 8,
//   start_month: 2,
//   start_date: 'sun',
//   is_display_content: true,
//   display_contents: [baby, child, adult, middle, elderly],
//   display_ages: [child,adult],
//   circle_color: '#FFA500',
//   role: 'general',
//   status: 'active',
//   created_at: toISO8601(new Date()),
//   updated_at: toISO8601(new Date()),
//   deleted_at: null,
// };

/*** [GET] /users ***/
export interface RequestUsersGet extends CommonAPIRequestType {}

export const usersGetAPI = () => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `users`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<User[]>({
    axios,
    path,
    query,
    form,
    method,
  });
};

/*** [GET] /users/{user_id} ***/
export interface RequestUsersIdGet extends CommonAPIRequestType {
  user_id: number;
}

export const usersIdGetAPI = (args: RequestUsersIdGet) => {
  const { user_id } = args;
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `users/${user_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<User>({
    axios,
    path,
    query,
    form,
    method,
  });
};

/*** [GET] /users/mail_address/{mail_address} ***/
export interface RequestUsersMailAddressGet extends CommonAPIRequestType {
  mail_address: string;
}

export const usersMailAddressGetAPI = (args: RequestUsersMailAddressGet) => {
  const { mail_address } = args;
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `users/mail_address/${mail_address}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<User>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: sample_user1,
  });
};

/*** [PUT] /users/{user_id} ***/
export interface RequestUsersIdPut extends CommonAPIRequestType {
  user_id: User['user_id'];
  home_page: User['home_page'];
  display_unit: User['display_unit'];
  display_period: User['display_period'];
  home_address?: string; // '20.43, 123.2'
  start_year?: User['start_year'];
  start_day?: User['start_day'];
  start_time?: User['start_time'];
  start_date?: User['start_date'];
  start_month?: User['start_month'];
  display_content?: string;
  display_age?: string;
  circle_color: User['circle_color'];
}

export const usersIdPutAPI = (params: RequestUsersIdPut) => {
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `users/${params.user_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;
  // for (const [key, value] of Object.entries(remain)) {
  //   addFormData({ form, key, value });
  // }

  // 送信
  return sendAxios<User>({
    axios,
    path,
    query,
    form,
    method,
  });
};
