import './App.css';
import { Router, Switch, Route } from 'react-router-dom';
import browserHistory from '@shared/browserHistory';
import dayjs from 'dayjs';
import 'dayjs/locale/ja'; // 必要なlocaleファイルをインポート
dayjs.locale('ja');

import UrbanTopPage from 'pages/urban/top/TopPage';
import FacilityPage from 'pages/facility/top/TopPage';
import FacilityEditPage from 'pages/facility/top/EditPage';
import TestPage from 'pages/test';
import SignUpConfirmPage from 'pages/login/signUpConfirm';
import { DgDocument } from 'components/DgDocument';
import { LoginPage } from 'pages/login/topPage';

function App() {
  return (
    <Router history={browserHistory}>
      <DgDocument>
        <Switch>
          <Route exact path='/login' component={LoginPage} />
          <Route exact path='/signUpConfirm' component={SignUpConfirmPage} />
          <Route exact path='/test' component={TestPage} />
          <Route exact path='/urban/top' component={() => <UrbanTopPage />} />
          <Route exact path='/facility/top/:map_id?' component={FacilityPage} />
          <Route exact path='/facility/top/edit/:map_id' component={FacilityEditPage} />
        </Switch>
      </DgDocument>
    </Router>
  );
}

export default App;
