import { SelectItem } from './SelectItem';

export type Gender = 'male' | 'female';

export const all_genders: Gender[] = ['male', 'female'];

export const hasAllGenders = (gender: Gender[]) => {
  const unique_generations = new Set(gender);
  return unique_generations.has('male') && unique_generations.has('female');
};

export const genderToJP = (gender: Gender) => {
  switch (gender) {
    case 'male':
      return '男性';
    case 'female':
      return '女性';
    default:
      return '';
  }
};

export const age_select_items: SelectItem<Gender>[] = [
  { value: 'male', label: genderToJP('male') },
  { value: 'female', label: genderToJP('female') },
];
