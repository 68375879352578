export const charts_colors = {
  male: '#3498DB',
  female: '#F85550',
  baby: '#FFCD55',
  child: '#02B5B2',
  adult: '#F56582',
  middle: '#50AD76',
  elderly: '#445771',
  linear_line: '#333333',
};

export type ChartsColorsKeys = keyof typeof charts_colors;
