import { getHourDateRange } from '@shared/functions/date';
import { PeriodSetting } from './PeriodSelect';
import dayjs, { Dayjs } from 'dayjs';
import { ISO8601 } from '@shared/models/ISO8601';
import { User } from 'api/users';
/**
 * period_settingから範囲(unixtime)を算出する関数
 * @param period_setting
 * @returns {start: number(unixtime), end: number(unixtime)}
 */
export const periodSettingToRange = (period_setting: PeriodSetting) => {
  const { date, period, unit, times } = period_setting;
  let start = 0;
  let end = 0;
  if (period === 'today') {
    const today = dayjs();
    start = dayjs(today).startOf(unit).unix();
    end = dayjs(today).endOf(unit).unix();
  } else if (period === 'period') {
    if (!date) return { start, end };
    if (unit === 'hour') {
      const { start: sdate, end: edate } = getHourDateRange(date, times?.[0], times?.[1]);
      start = sdate.unix();
      end = edate.unix();
    } else {
      start = dayjs(date).startOf(unit).unix();
      end = dayjs(date).endOf(unit).unix();
    }
  }
  return { start, end };
};

/**
 * period_settingから範囲(ISO8601)を算出する関数
 * @param period_setting
 * @returns {start: ISO8601, end: ISO8601}
 */
export const periodSettingToIso8601Range = (period_setting: PeriodSetting): { start: ISO8601; end: ISO8601 } => {
  const { date, period, unit, times } = period_setting;
  let start: Dayjs = dayjs();
  let end: Dayjs = dayjs();
  if (period === 'today') {
    const today = dayjs();
    start = dayjs(today).startOf(unit);
    end = dayjs(today).endOf(unit);
  } else if (period === 'period') {
    if (!date) return { start: start.toISOString() as ISO8601, end: end.toISOString() as ISO8601 };
    if (unit === 'hour') {
      const { start: sdate, end: edate } = getHourDateRange(date, times?.[0], times?.[1]);
      start = sdate;
      end = edate;
    } else {
      start = dayjs(date).startOf(unit);
      end = dayjs(date).endOf(unit);
    }
  }
  return { start: start.toISOString() as ISO8601, end: end.toISOString() as ISO8601 };
};

export const userToPeriodSetting = (user: User | null): PeriodSetting => {
  if (!user) {
    return {
      unit: 'hour',
      period: 'today',
    };
  }
  const { display_period, display_unit, start_year, start_date, start_month, start_time } = user;
  if (display_period === 'today') {
    const today: Dayjs = dayjs();
    const start_hour = today.hour();
    const end_hour = start_hour + 1;
    return {
      unit: display_unit,
      period: 'today',
      date: today,
      times: display_unit === 'hour' ? [start_hour, end_hour] : undefined,
    };
  }
  let dt = dayjs();
  let times: [number, number] | undefined = undefined;
  if (typeof start_year === 'number') {
    dt = dt.set('year', start_year);
  }
  if (typeof start_month === 'number') {
    dt = dt.set('month', start_month);
  }
  if (typeof start_date === 'number') {
    dt = dt.set('date', start_date);
  }
  if (typeof start_time === 'number') {
    times = [start_time, start_time + 1];
  }
  return {
    unit: display_unit,
    period: 'period',
    date: dt,
    times,
  };
};
