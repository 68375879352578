import { SelectItem } from './SelectItem';

export type Content = 'humanTraffic' | 'gender';

// Content 型の値に該当するかどうかをチェックする関数
export const isContent = (value: string): value is Content => {
  return value === 'humanTraffic' || value === 'gender';
};

// Content 型に変換する関数
export const change_contents = (params?: string) => {
  const contents = params?.split(', ');
  const result: Content[] = contents ? contents.filter(isContent) : [];
  return result;
};

// Content[]をバックエンドに対応するように結合する
export const joinContents = (contents?: Content[]) => {
  if (!contents) return undefined;
  return contents.join(', ');
};

export const hasHumanTraffic = (contents: Content[]) => {
  return contents.includes('humanTraffic');
};

export const contentToJP = (content: Content) => {
  switch (content) {
    case 'humanTraffic':
      return '人流';
    case 'gender':
      return '性別';
    default:
      return '';
  }
};

export const content_select_items: SelectItem<string>[] = [
  { value: 'humanTraffic', label: contentToJP('humanTraffic') },
  { value: 'gender', label: contentToJP('gender') },
];
