// カスタムマーカーアイコンの画像ファイルをインポート;
import { Camera } from 'api/cameras';
import { Map, mapsImageGetAPI } from 'api/maps';
import { useEffect, useState } from 'react';
import { CameraDetailModal } from 'components/CameraDetailModal';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { FacilityCameraMetaIcon } from 'components/CameraMetaIcon/FacilityCameraMetaIcon';

export interface FacilityMapProps {
  map?: Map;
  cameras?: Camera[];
  period_setting: PeriodSetting;
  age_setting: AgeSetting;
  content_setting: ContentSetting;
  device_type: string;
  circle_color: string;
}

export function FacilityMap({
  map,
  cameras,
  period_setting,
  age_setting,
  content_setting,
  device_type,
  circle_color,
}: FacilityMapProps) {
  const [selected_camera, setSelectedCamera] = useState<Camera | null>(null);
  const [img_url, setImgUrl] = useState<string>();

  function onCustomModalClose() {
    setSelectedCamera(null);
  }
  function onCustomCameraMarkerClick(camera: Camera) {
    setSelectedCamera(camera);
  }

  useEffect(() => {
    async function loadImage() {
      if (map) {
        const res = await mapsImageGetAPI({ map_id: map.map_id });
        if (res.status === 200) {
          const url = window.URL || window.webkitURL;
          const img_url = url.createObjectURL(res.data);
          setImgUrl(img_url);
        }
      }
    }
    loadImage();
  }, [map]);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative', overflow: 'auto' }}>
      <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
        <img style={{ width: '100%', height: 'auto' }} src={img_url} />
        {selected_camera && (
          <CameraDetailModal
            period_setting={period_setting}
            open={!!selected_camera}
            style={{ left: 30, top: 90 }}
            camera={selected_camera}
            onCancel={onCustomModalClose}
            device_type={device_type}
          />
        )}
        {map &&
          cameras?.map((camera) => {
            return (
              <FacilityCameraMetaIcon
                camera={camera}
                selected_camera={selected_camera}
                period_setting={period_setting}
                age_setting={age_setting}
                content_setting={content_setting}
                onCameraClick={onCustomCameraMarkerClick}
                key={camera.camera_id}
                circle_color={circle_color}
              />
            );
          })}
      </div>
    </div>
  );
}
