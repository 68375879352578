import { Button, Col, Divider, Drawer, Menu, Row, Select, Space } from 'antd';
import { useBoolean } from '@shared/hooks/useBoolean/useBoolean';
import { CaretRightOutlined, MenuOutlined } from '@ant-design/icons';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { hasHumanTraffic } from '@shared/models/Content';
import { Map, mapsIdGetAPI } from 'api/maps';
import { PeriodSettingEngine } from 'components/PeriodSettingEngine';
import { ContentSettingEngine } from 'components/ContentSettingEngine';
import { AgeSettingEngine } from 'components/AgeSettingEngine';
import { useEffect, useMemo, useState } from 'react';
import { MenuItemProps } from 'antd/es/menu/MenuItem';
import { MenuItem, getMenuItem } from '@shared/components/Antd/Menu';
import { useMaps } from 'hooks/useMaps/useMaps';
import { Typography } from 'antd';

export interface FacilityUserMenuDrawerProps {
  period_setting: PeriodSetting;
  customUpdatePeriodSetting(form: PeriodSetting): void;
  content_setting: ContentSetting;
  customUpdateContentSetting(form: ContentSetting): void;
  age_setting: AgeSetting;
  onAgeSettingModalFinish(form: AgeSetting): void;
  maps: Map[];
  map_id?: number;
  selected_facility_map?: Map;
  setSelectedFacilityMap(facility_map?: Map): void;
}

export function FacilityUserMenuDrawer(params: FacilityUserMenuDrawerProps) {
  const { bool: open, onOpen, onClose } = useBoolean();
  const [selected_map, setSelectedMap] = useState<Map | undefined>(params.maps?.[0]);
  const { maps: facility_maps, maps_record: facility_maps_record } = useMaps(selected_map?.map_id);

  const facility_map_selected_keys = useMemo(() => {
    const result: string[] = [];
    if (typeof params.selected_facility_map?.map_id === 'string') {
      result.push(params.selected_facility_map.map_id);
    }
    return result;
  }, [params.selected_facility_map?.map_id]);

  const map_items: MenuItem[] =
    facility_maps?.map((map) => getMenuItem(map.facility_name, map.map_id, <CaretRightOutlined />)) ?? [];

  // フロア選択
  const onSelectedFacilityChange: MenuItemProps['onClick'] = (new_facility) => {
    params.setSelectedFacilityMap(facility_maps_record[new_facility.key]);
  };
  // 施設の選択
  const onFacilityMapSlect = async (map_id: number) => {
    const res = await mapsIdGetAPI({ map_id: map_id });
    setSelectedMap(res.data);
    params.setSelectedFacilityMap();
  };

  useEffect(() => {
    async function loadMap() {
      if (typeof params.map_id === 'number') {
        const res = await mapsIdGetAPI({ map_id: params.map_id });
        setSelectedMap(res.data);
      }
    }
    loadMap();
  }, [params.map_id]);

  return (
    <Space>
      <Button shape='circle' icon={<MenuOutlined />} style={{ zIndex: 2 }} onClick={onOpen} size='large' />
      <Drawer placement='left' width={220} onClose={onClose} open={open}>
        <Row gutter={[16, 8]}>
          <Typography.Title level={5}>カメラ表示</Typography.Title>
          <Col span={24}>
            <PeriodSettingEngine
              form={params.period_setting}
              updateForm={params.customUpdatePeriodSetting}
              input_style={{ maxWidth: 200 }}
            />
            <ContentSettingEngine updateForm={params.customUpdateContentSetting} form={params.content_setting} />
            {hasHumanTraffic(params.content_setting.contents) && (
              <AgeSettingEngine updateForm={params.onAgeSettingModalFinish} form={params.age_setting} />
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: '5px' }}>
          <Col span={24}>
            <Typography.Title level={5}>施設選択</Typography.Title>
            <Select
              style={{ width: '100%' }}
              value={selected_map?.map_id}
              onChange={(e) => onFacilityMapSlect(e)}
              options={params.maps?.map((map) => {
                return { value: map.map_id, label: map.map_name };
              })}
            />
            <Menu
              selectedKeys={facility_map_selected_keys}
              items={map_items}
              onClick={(item) => onSelectedFacilityChange?.(item)}
            />
            <Divider style={{ margin: '5px 0px ', backgroundColor: '#CCC' }} />
          </Col>
        </Row>
      </Drawer>
    </Space>
  );
}
