export function MaximizeSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 20 20'
      enableBackground='new 0 0 20 20'
      xmlSpace='preserve'
      {...props}
    >
      <g id='maximize_2_'>
        <g>
          <path
            fill='currentColor'
            d='M19,0h-5c-0.55,0-1,0.45-1,1s0.45,1,1,1h2.59l-5.29,5.29
            C11.11,7.47,11,7.72,11,8c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L18,3.41V6c0,0.55,0.45,1,1,1s1-0.45,1-1V1
            C20,0.45,19.55,0,19,0z M8,11c-0.28,0-0.53,0.11-0.71,0.29L2,16.59V14c0-0.55-0.45-1-1-1s-1,0.45-1,1v5c0,0.55,0.45,1,1,1h5
            c0.55,0,1-0.45,1-1s-0.45-1-1-1H3.41l5.29-5.29C8.89,12.53,9,12.28,9,12C9,11.45,8.55,11,8,11z'
          />
        </g>
      </g>
    </svg>
  );
}
