import { Space } from 'antd';
import { SearchEngine } from './SearchEngine';
import { PeriodSettingEngine } from 'components/PeriodSettingEngine';
import { ContentSettingEngine } from 'components/ContentSettingEngine';
import { useObj } from '@shared/hooks/useObj/useObj';
import { useViewportSize } from '@shared/hooks/useViewPortSize/useViewPortSize';
import { all_ages } from '@shared/models/Age';
import { BaseMap } from './BaseMap';
import { useCameras } from 'hooks/useCameras/useCameras';
import { useMemo, useState } from 'react';
import { Camera } from 'api/cameras';
import { hasHumanTraffic } from '@shared/models/Content';
import { AccountSettingSetting } from 'components/AccountSetting';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { AgeSettingEngine } from 'components/AgeSettingEngine';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { UserMenuDrawer } from 'components/UserMenuDrawer';
import { user_atom } from 'customJotai/atom';
import { useAtom } from 'jotai';
import { userToPeriodSetting } from 'components/PeriodSettingEngine/functions';

const isSearchedCamera = (searched_camera: Camera) => (camera: Camera) => {
  return camera.camera_id === searched_camera.camera_id;
};

export default function TopPage() {
  const [user] = useAtom(user_atom);
  const { obj: content_setting, updateObj: updateContentSetting } = useObj<ContentSetting>({
    contents: user?.display_content ?? [],
  });
  const { obj: age_setting, updateObj: updateAgeSetting } = useObj<AgeSetting>({
    ages: user?.display_age ?? all_ages,
  });
  //ユーザの情報に基づく地図表示項目を設定
  const { obj: period_setting, updateObj: updatePeriodSetting } = useObj<PeriodSetting>(userToPeriodSetting(user));
  const home_address = user?.home_address ?? [35.6581, 139.7017]; //ピンの設定が無ければ初期値(渋谷)を設定
  const circle_color = user
    ? typeof user.circle_color === 'object'
      ? user.circle_color.toHexString()
      : user.circle_color
    : 'orange'; //人流カラーピッカーを設定していなければ初期値(オレンジ)を設定
  const { cameras } = useCameras();
  const { device_type } = useViewportSize();
  const [searched_camera, setSearchedCamera] = useState<Camera | null>(null);
  const displaying_map_cameras = useMemo(() => {
    if (!searched_camera) return cameras;
    return cameras?.filter(isSearchedCamera(searched_camera));
  }, [cameras, searched_camera]);

  const customUpdatePeriodSetting = (form: PeriodSetting) => {
    updatePeriodSetting(form);
  };

  const customUpdateContentSetting = (form: ContentSetting) => {
    const { contents } = form;
    if (hasHumanTraffic(contents)) {
      updateAgeSetting({
        ages: all_ages,
      });
    }
    updateContentSetting(form);
  };

  const onAgeSettingModalFinish = (form: AgeSetting) => {
    updateAgeSetting(form);
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <BaseMap
        cameras={displaying_map_cameras}
        content_setting={content_setting}
        period_setting={period_setting}
        age_setting={age_setting}
        device_type={device_type}
        home_address={home_address}
        circle_color={circle_color}
        user={user}
      />
      {device_type == 'sp' ? (
        <div style={{ width: '100%', position: 'absolute' }}>
          <Space style={{ width: '100%', height: '100%' }} direction='vertical'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
              <UserMenuDrawer
                searched_camera={searched_camera}
                setSearchedCamera={setSearchedCamera}
                period_setting={period_setting}
                customUpdatePeriodSetting={customUpdatePeriodSetting}
                content_setting={content_setting}
                customUpdateContentSetting={customUpdateContentSetting}
                age_setting={age_setting}
                onAgeSettingModalFinish={onAgeSettingModalFinish}
              />
              <Space style={{ zIndex: 2 }}>
                <AccountSettingSetting />
              </Space>
            </div>
          </Space>
        </div>
      ) : (
        <div style={{ width: '100%', position: 'absolute' }}>
          <Space style={{ width: '100%', height: '100%' }} direction='vertical'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
              <Space size={8} style={{ zIndex: 2 }}>
                <SearchEngine setSearchedCamera={setSearchedCamera} searched_camera={searched_camera} />

                <PeriodSettingEngine
                  form={period_setting}
                  updateForm={customUpdatePeriodSetting}
                  input_style={{ maxWidth: 250 }}
                />
                <ContentSettingEngine updateForm={customUpdateContentSetting} form={content_setting} />
                {hasHumanTraffic(content_setting.contents) && (
                  <AgeSettingEngine updateForm={onAgeSettingModalFinish} form={age_setting} />
                )}
              </Space>
              <Space style={{ zIndex: 2 }}>
                <AccountSettingSetting />
              </Space>
            </div>
          </Space>
        </div>
      )}
    </div>
  );
}
