import history from '@shared/browserHistory';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

type SignUpConfirmFieldType = {
  username: string;
  code: string;
};

export default function SignUpConfirmPage() {
  async function confirmSignUp(values: SignUpConfirmFieldType) {
    const { username, code } = values;
    try {
      const res = await Auth.confirmSignUp(username, code);
      if (res.status === 200) {
        history.push('/login');
      }
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }
  return (
    <Row
      gutter={[24, 24]}
      style={{
        maxWidth: 300,
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translateY(-40%) translateX(-50%)',
      }}
    >
      <Col span={24}>
        <Typography.Title>IDEA DemoGrid</Typography.Title>
      </Col>
      <Col span={24}>
        <Form onFinish={confirmSignUp} requiredMark={false} layout='vertical'>
          <Form.Item<SignUpConfirmFieldType>
            label='ユーザー名'
            name='username'
            rules={[{ required: true, message: 'ユーザー名を入力してください' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<SignUpConfirmFieldType>
            label='認証コード'
            name='code'
            rules={[
              {
                required: true,
                message: 'パスワードを入力してください',
              },
              {
                len: 6,
                message: '認証コードは6桁の数値です',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Link to='/login'>ログインページに戻る</Link>
          </Form.Item>
          <Form.Item>
            <Button type='primary' shape='round' htmlType='submit'>
              サインアップ完了
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
