import { Unit } from '@shared/models/Unit';
import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';

const date_format = 'YYYY/MM/DD';

export interface DynamicDatePickerProps {
  date?: Dayjs;
  unit: Unit;
  updateDate(date?: Dayjs): void;
}
export function DynamicDatePicker({ date, updateDate, unit }: DynamicDatePickerProps) {
  const onDateChange = (value: Dayjs | null) => {
    if (value === null) {
      updateDate(undefined);
      return;
    }
    updateDate(value);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const date_picker_props: any = {
    value: date,
    onChange: onDateChange,
    style: { width: '100%' },
  };
  switch (unit) {
    case 'hour':
      date_picker_props.format = date_format;
      break;
    case 'day':
      date_picker_props.format = date_format;
      break;
    case 'week':
      date_picker_props.picker = 'week';
      break;
    case 'month':
      date_picker_props.picker = 'month';
      break;
  }
  return <DatePicker {...date_picker_props} />;
}
