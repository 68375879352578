import { SelectItem } from './SelectItem';

export type Unit = 'hour' | 'day' | 'week' | 'month';

export const display_unit_select_items: SelectItem<Unit>[] = [
  { value: 'hour', label: '時' },
  { value: 'day', label: '日' },
  { value: 'week', label: '週' },
  { value: 'month', label: '月' },
];
