import { Button, Col, ColorPicker, Drawer, RadioChangeEvent, Row, Space, Typography } from 'antd';
import { useBoolean } from '@shared/hooks/useBoolean/useBoolean';
import { useObj } from '@shared/hooks/useObj/useObj';
import { hasHumanTraffic } from '@shared/models/Content';
import { all_ages } from '@shared/models/Age';
import { SettingOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { PeriodSelect, PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { ContentSelect, ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { AgeSelect, AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { user_atom } from 'customJotai/atom';
import { RequestUsersIdPut, usersIdPutAPI } from 'api/users';
import { TitleTaggedComponent } from '@shared/components/TitleTaggedComponent';
import { RadioInput } from '@shared/components/RadioInput';
import { ViewType, view_type_select_items } from '@shared/models/ViewType';
import { useAtom } from 'jotai';
import { Color } from 'antd/es/color-picker';
import { logOut } from 'utils/auth/auth';
import { userToPeriodSetting } from 'components/PeriodSettingEngine/functions';

interface Form {
  home_page: ViewType;
  period_setting: PeriodSetting;
  content_setting: ContentSetting;
  age_setting: AgeSetting;
  color: string | Color;
}

export function AccountSettingSetting() {
  const [user, setUser] = useAtom(user_atom);
  const { bool: open, onOpen, onClose } = useBoolean();
  const {
    obj: form,
    updateObj: updateForm,
    setObj: setForm,
  } = useObj<Form>({
    home_page: user?.home_page ?? 'urban',
    period_setting: userToPeriodSetting(user),
    content_setting: {
      contents: user?.display_content ?? [],
    },
    age_setting: { ages: user?.display_age ?? all_ages },
    color: user?.circle_color ?? 'orange',
  });

  const updateHomePage = (e: RadioChangeEvent) => {
    const { value } = e.target;
    updateForm({ home_page: value as ViewType });
  };

  const updatePeriodSetting = (period_setting: PeriodSetting) => {
    updateForm({ period_setting });
  };
  const updateContentSetting = (content_setting: ContentSetting) => {
    updateForm({ content_setting });
  };

  const updateAgeSetting = (generation_setting: AgeSetting) => {
    updateForm({ age_setting: generation_setting });
  };

  const onPostForm = async () => {
    if (!user) {
      console.log('ログインしてください');
      logOut();
      return;
    }
    const { content_setting, age_setting, period_setting, home_page, color } = form;
    const is_custom_period = period_setting.period === 'period';
    const is_hour = period_setting.unit === 'hour';
    const is_day = period_setting.unit === 'day';
    const is_week = period_setting.unit === 'week';
    // カンマ区切りの文字列に変換
    const display_content = content_setting.contents.join(', ');
    const display_age = age_setting.ages.join(', ');
    const circle_color = typeof color === 'string' ? color : color.toHexString();
    const request: RequestUsersIdPut = {
      user_id: user.user_id,
      home_page,
      display_unit: period_setting.unit,
      display_period: period_setting.period,
      start_year: is_custom_period ? period_setting.date?.year() : null,
      start_month: is_custom_period ? period_setting.date?.month() : null,
      start_date: is_custom_period && (is_hour || is_day || is_week) ? period_setting.date?.date() : null,
      start_time: is_custom_period && is_hour ? period_setting.times?.[0] : null,
      display_content,
      display_age,
      circle_color,
    };
    const res = await usersIdPutAPI(request);
    if (res.status === 200) {
      setUser(res.data);
      onClose();
    }
  };

  useEffect(() => {
    if (!open || !user) return;
    const new_form: Form = {
      home_page: user.home_page,
      color: user.circle_color,
      period_setting: userToPeriodSetting(user),
      content_setting: { contents: user.display_content ?? [] },
      age_setting: { ages: user.display_age },
    };
    setForm(new_form);
  }, [user, open, setForm]);

  return (
    <Space>
      <Button shape='circle' icon={<SettingOutlined />} style={{ zIndex: 2 }} onClick={onOpen} size='large' />
      <Drawer
        title={
          <Row align='middle'>
            <Col span={16}>
              <Typography.Text>デフォルト表示設定</Typography.Text>
            </Col>
            <Col span={8}>
              <Button shape='round' type='primary' danger size='small' title='ログアウト' onClick={logOut}>
                ログアウト
              </Button>
            </Col>
          </Row>
        }
        placement='right'
        width={350}
        onClose={onClose}
        open={open}
      >
        <Space direction='vertical' size={16} style={{ width: '100%' }}>
          <TitleTaggedComponent title='トップページ設定' direction='vertical'>
            <RadioInput items={view_type_select_items} value={form.home_page} onChange={updateHomePage} />
          </TitleTaggedComponent>
          <PeriodSelect form={form.period_setting} updateForm={updatePeriodSetting} />
          <ContentSelect form={form.content_setting} updateForm={updateContentSetting} />
          {hasHumanTraffic(form.content_setting.contents) && (
            <AgeSelect form={form.age_setting} updateForm={updateAgeSetting} />
          )}
          <TitleTaggedComponent title='人流カラーピッカー' direction='vertical'>
            <ColorPicker size='middle' showText value={form.color} onChange={(value) => updateForm({ color: value })} />
          </TitleTaggedComponent>

          <Row justify='center' align='middle' gutter={[0, 12]}>
            <Col>
              <Button type='primary' shape='round' style={{ width: 180 }} onClick={onPostForm}>
                更新
              </Button>
            </Col>
            <Col>
              <Typography.Text>ここで設定した内容はログアウト後も保存されます</Typography.Text>
            </Col>
          </Row>
        </Space>
      </Drawer>
    </Space>
  );
}
