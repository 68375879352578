import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from '@shared/functions';
import { MapCamera, mapsCamerasGetAPI } from 'api/maps';

/**
 * マップに紐づくデバイス一覧を取得するcustom hooks
 */
export const useMapsCameras = (map_id?: number) => {
  const [map_cameras, setMapsCameras] = useState<MapCamera[] | undefined>(undefined);
  const map_cameras_record: Record<string, MapCamera> = useMemo(() => {
    return map_cameras ? keyBy(map_cameras, (d) => String(d.camera_id)) : {};
  }, [map_cameras]);
  const loadMapsCameras = useCallback(async () => {
    setMapsCameras(undefined);
    if (!map_id) return;
    const res = await mapsCamerasGetAPI({ map_id });
    if (res.status === 200) {
      setMapsCameras(res.data);
    }
  }, [map_id]);

  useEffect(() => {
    loadMapsCameras();
  }, [loadMapsCameras]);

  return useMemo(() => {
    return {
      map_cameras,
      map_cameras_record,
      setMapsCameras,
      loadMapsCameras,
    };
  }, [map_cameras, map_cameras_record, setMapsCameras, loadMapsCameras]);
};
