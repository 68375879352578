import { Space, Col, Row, Checkbox } from 'antd';
import { Age, age_select_items } from '@shared/models/Age';
import { TitleTaggedComponent } from '@shared/components/TitleTaggedComponent';

export interface AgeSetting {
  ages: Age[];
}
export interface AgeSelectProps {
  form: AgeSetting;
  updateForm(form: AgeSetting): void;
}
export function AgeSelect({ form, updateForm }: AgeSelectProps) {
  return (
    <Space size={[0, 8]} direction='vertical' style={{ width: '100%' }}>
      <TitleTaggedComponent title='年代' direction='vertical'>
        <Checkbox.Group
          value={form.ages}
          onChange={(checked_value) => updateForm({ ...form, ages: checked_value as Age[] })}
        >
          <Row gutter={[16, 16]}>
            {age_select_items.map((gsi) => {
              return (
                <Col span={12} key={gsi.value}>
                  <Checkbox value={gsi.value}>{gsi.label}</Checkbox>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      </TitleTaggedComponent>
    </Space>
  );
}
