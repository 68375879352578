import { SelectItem } from './SelectItem';

export type ViewType = 'urban' | 'facility';

export const viewTypeToJP = (view_type: ViewType) => {
  switch (view_type) {
    case 'urban':
      return 'UrbanView';
    case 'facility':
      return 'FacilityView';
    default:
      return '';
  }
};

export const view_type_select_items: SelectItem<ViewType>[] = [
  { value: 'urban', label: viewTypeToJP('urban') },
  { value: 'facility', label: viewTypeToJP('facility') },
];
