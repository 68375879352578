import { CaretRightOutlined, SettingOutlined } from '@ant-design/icons';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import { Row, Col } from 'antd/es/grid';
import Menu, { MenuItemProps } from 'antd/es/menu';
import Select from 'antd/es/select';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { useMaps } from 'hooks/useMaps/useMaps';
import { useState, useEffect, useMemo } from 'react';
import { FacilityMap } from './FacilityMap';
import { Map, mapsIdGetAPI } from 'api/maps';

import history from '@shared/browserHistory';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { MenuItem, getMenuItem } from '@shared/components/Antd/Menu';
import { useMapsCameras } from 'hooks/useMapsCameras/useMapsCameras';
import { FacilityUserMenuDrawer } from 'components/FacilityUserMenuDrawer';
import { UpdateObj } from '@shared/hooks/useObj/useObj';

/**
 * Facility View トップページのBody
 */
export interface FacilityTopBodyProps {
  map_id?: number;
  period_setting: PeriodSetting;
  updatePeriodSetting: UpdateObj<PeriodSetting>;
  age_setting: AgeSetting;
  updateAgeSetting: UpdateObj<AgeSetting>;
  content_setting: ContentSetting;
  updateContentSetting: UpdateObj<ContentSetting>;
  device_type: string;
  circle_color: string;
}

export function FacilityTopBody({
  map_id,
  period_setting,
  updatePeriodSetting,
  age_setting,
  updateAgeSetting,
  content_setting,
  updateContentSetting,
  device_type,
  circle_color,
}: FacilityTopBodyProps) {
  const [selected_facility_map, setSelectedFacilityMap] = useState<Map | undefined>();
  const { map_cameras } = useMapsCameras(selected_facility_map?.map_id);
  const { maps } = useMaps(undefined, true);

  return (
    <div
      style={{ display: 'flex', height: 'calc(100% - 60px)', justifyContent: 'flex-start', alignItems: 'flex-start' }}
    >
      {device_type != 'sp' ? (
        <SideMenu
          maps={maps || []}
          map_id={map_id}
          selected_facility_map={selected_facility_map}
          setSelectedFacilityMap={setSelectedFacilityMap}
        />
      ) : (
        <FacilityUserMenuDrawer
          period_setting={period_setting}
          customUpdatePeriodSetting={updatePeriodSetting}
          content_setting={content_setting}
          customUpdateContentSetting={updateContentSetting}
          age_setting={age_setting}
          onAgeSettingModalFinish={updateAgeSetting}
          maps={maps || []}
          map_id={map_id}
          selected_facility_map={selected_facility_map}
          setSelectedFacilityMap={setSelectedFacilityMap}
        />
      )}
      {selected_facility_map && (
        <FacilityMap
          map={selected_facility_map}
          cameras={map_cameras}
          period_setting={period_setting}
          age_setting={age_setting}
          content_setting={content_setting}
          device_type={device_type}
          circle_color={circle_color}
        />
      )}
    </div>
  );
}

/**
 * Facility ViewのトップページのSideMenu
 */
interface SideMenuProps {
  maps: Map[];
  map_id?: number;
  selected_facility_map?: Map;
  setSelectedFacilityMap(facility_map?: Map): void;
}
function SideMenu({ maps, map_id, selected_facility_map, setSelectedFacilityMap }: SideMenuProps) {
  const [selected_map, setSelectedMap] = useState<Map | undefined>();
  const { maps: facility_maps, maps_record: facility_maps_record } = useMaps(selected_map?.map_id);
  const onSelectedMapChange = (new_map_id: number) => {
    history.push(`/facility/top/${new_map_id}`);
  };
  const map_items: MenuItem[] = selected_map
    ? facility_maps?.map((map) => getMenuItem(map.facility_name, map.map_id, <CaretRightOutlined />)) || []
    : [];

  // フロア選択
  const onSelectedFacilityChange: MenuItemProps['onClick'] = (new_facility) => {
    if (selected_map) {
      setSelectedFacilityMap(facility_maps_record[new_facility.key]);
    }
  };
  // 施設編集への遷移
  const onFacilityEdited = () => {
    history.push(`/facility/top/edit/${selected_map?.map_id}`);
  };

  const facility_map_selected_keys = useMemo(() => {
    const result: string[] = [];
    if (typeof selected_facility_map?.map_id === 'string') {
      result.push(selected_facility_map.map_id);
    }
    return result;
  }, [selected_facility_map?.map_id]);

  useEffect(() => {
    async function loadMap() {
      if (map_id) {
        const res = await mapsIdGetAPI({ map_id });
        setSelectedMap(res.data);
      }
    }
    loadMap();
  }, [map_id]);

  useEffect(() => {
    if (selected_map) {
      setSelectedFacilityMap(facility_maps?.[0]);
    }
  }, [facility_maps, selected_map, setSelectedFacilityMap]);

  return (
    <div style={{ width: '200px', borderRight: '1px solid #808080', padding: '10px', textAlign: 'left' }}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <Select
            style={{ width: '100%' }}
            value={selected_map?.map_id}
            onChange={onSelectedMapChange}
            options={maps?.map((map) => {
              return { value: map.map_id, label: map.map_name };
            })}
          />
        </Col>
        <Col span={24}>
          <Menu
            selectedKeys={facility_map_selected_keys}
            items={map_items}
            onClick={(item) => onSelectedFacilityChange?.(item)}
          />
          <Divider style={{ margin: '5px 0px ', backgroundColor: '#CCC' }} />
        </Col>
        <Col span={24}>
          <Button onClick={onFacilityEdited} type='primary' shape='round'>
            <SettingOutlined /> 施設の登録・編集
          </Button>
        </Col>
      </Row>
    </div>
  );
}
