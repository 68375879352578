export function MinimizeSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 20 20'
      enableBackground='new 0 0 20 20'
      xmlSpace='preserve'
      {...props}
    >
      <g id='minimize_2_'>
        <g>
          <path
            fill='currentColor'
            d='M8,11H3c-0.55,0-1,0.45-1,1s0.45,1,1,1h2.59l-5.29,5.29C0.11,18.47,0,18.72,0,19
            c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L7,14.41V17c0,0.55,0.45,1,1,1s1-0.45,1-1v-5C9,11.45,8.55,11,8,11z M20,1
            c0-0.55-0.45-1-1-1c-0.28,0-0.53,0.11-0.71,0.29L13,5.59V3c0-0.55-0.45-1-1-1s-1,0.45-1,1v5c0,0.55,0.45,1,1,1h5
            c0.55,0,1-0.45,1-1s-0.45-1-1-1h-2.59l5.29-5.29C19.89,1.53,20,1.28,20,1z'
          />
        </g>
      </g>
    </svg>
  );
}
