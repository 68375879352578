import { User } from 'api/users';
import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jotaiStorage = createJSONStorage<any>(() => sessionStorage);

export const atomWithEncryptedStorage = <T>(key: string) => {
  const initial_str = window.sessionStorage.getItem(key);
  const initial: T = initial_str ? JSON.parse(initial_str) : null;

  return atomWithStorage<T>(key, initial, jotaiStorage);
};

export const user_atom = atomWithEncryptedStorage<User | null>('user');

export const access_token_atom = atomWithEncryptedStorage<string | null>('access_token');

export const isReadyUserAtom = atom<boolean>((get) => {
  const user = get(user_atom);
  return user !== undefined;
});
