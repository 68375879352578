import { useState, useEffect } from 'react';
export type WindowSize = {
  width: number;
  height: number;
};

type DeviceType = 'pc' | 'tab' | 'sp';

// カスタムフックを使用してビューポートのサイズを取得する
export const useViewportSize = () => {
  const [size, setSize] = useState<WindowSize>({ width: window.innerWidth, height: window.innerHeight });
  let device_type: DeviceType = 'pc';

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    // イベントリスナーを登録
    window.addEventListener('resize', handleResize);

    // クリーンアップ関数
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (size.width <= 1020) {
    device_type = size.width > 565 ? 'tab' : 'sp';
  }

  return { device_type, size };
};
