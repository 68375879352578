import { all_ages } from '@shared/models/Age';
import { all_directions } from '@shared/models/Direction';
import { Gender, all_genders } from '@shared/models/Gender';
import { Countdata, countdataCameraIdGetAPI, AgeGroups, sumAgeGroups } from 'api/countdata';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { CameraMarkerIconProps } from 'components/FacilityIcons/CameraMarkerIcon';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { periodSettingToIso8601Range } from 'components/PeriodSettingEngine/functions';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { getCameraCircleSize } from 'utils/functions';

/**
 * CameraMetaIconに関するロジックをまとめたhooks
 */
export interface useCameraMetaIconArgs {
  camera_id: number;
  content_setting: ContentSetting;
  age_setting: AgeSetting;
  period_setting: PeriodSetting;
  zoom_level?: number;
}
export function useCameraMetaIcon({
  camera_id,
  content_setting,
  age_setting,
  period_setting,
  zoom_level,
}: useCameraMetaIconArgs) {
  const [countdata_list, setCountdataList] = useState<Countdata[] | undefined>(undefined);
  const use_human_traffic = content_setting?.contents.includes('humanTraffic');
  const use_gender = content_setting?.contents.includes('gender');
  // countdataをロードする関数
  const loadCountdataList = useCallback(async () => {
    const { start, end } = periodSettingToIso8601Range(period_setting);
    setCountdataList(undefined);
    // [TODO] camera_idをcamera.camera_idから指定する
    const res = await countdataCameraIdGetAPI({ camera_id: camera_id, start, end, unit: period_setting.unit });
    let new_countdata_list: Countdata[] = [];
    if (res.status === 200) {
      new_countdata_list = res.data;
    }
    setCountdataList(new_countdata_list);
  }, [camera_id, period_setting]);
  // データ表示用に整形したcountdataを保持しておく
  const formated_countdata_list = useMemo(() => {
    if (!countdata_list) return null;
    const result: Record<Gender, AgeGroups> = {
      male: {
        baby: 0,
        child: 0,
        adult: 0,
        middle: 0,
        elderly: 0,
      },
      female: {
        baby: 0,
        child: 0,
        adult: 0,
        middle: 0,
        elderly: 0,
      },
    };
    // countdataのデータをresultに加算していく
    countdata_list.forEach((countdata) => {
      const { metrics } = countdata;
      all_directions.forEach((direction) => {
        all_genders.forEach((gender) => {
          all_ages.forEach((age) => {
            result[gender][age] += metrics[direction][gender][age];
          });
        });
      });
    });
    return result;
  }, [countdata_list]);
  // 画面に表示するデータ
  const content_meta_data = useMemo(() => {
    if (!formated_countdata_list) return null;
    if (!use_human_traffic && !use_gender) return null;

    const result: {
      human_traffic?: number;
      gender?: {
        male: number;
        female: number;
        male_percent: number;
        female_percent: number;
      };
    } = {};

    if (use_human_traffic) {
      result.human_traffic = 0;
      const partial_male: Partial<AgeGroups> = {};
      const partial_female: Partial<AgeGroups> = {};
      age_setting?.ages.forEach((age) => {
        partial_male[age] = formated_countdata_list.male[age];
        partial_female[age] = formated_countdata_list.female[age];
      });
      const total = sumAgeGroups(partial_male) + sumAgeGroups(partial_female);
      result.human_traffic = total;
    }
    if (use_gender) {
      result.gender = {
        male: 0,
        female: 0,
        male_percent: 0,
        female_percent: 0,
      };
      const male_total = sumAgeGroups(formated_countdata_list.male);
      const female_total = sumAgeGroups(formated_countdata_list.female);
      // 1埋め
      const gender_total = male_total + female_total || 1;
      const male_percent = (male_total / gender_total) * 100;
      const female_percent = (female_total / gender_total) * 100;
      result.gender = {
        male: male_total,
        female: female_total,
        male_percent,
        female_percent,
      };
    }
    return result;
  }, [formated_countdata_list, use_gender, use_human_traffic, age_setting]);
  // tooltipの説明
  const tooltip_descriptions = useMemo(() => {
    const result: { key: string; label: string }[] = [];
    if (typeof content_meta_data?.human_traffic === 'number') {
      result.push({
        key: 'human_traffic',
        label: `合計：${content_meta_data.human_traffic}人`,
      });
    }
    if (content_meta_data?.gender) {
      result.push({
        key: 'gender',
        label: `男性：${content_meta_data.gender.male_percent.toFixed(
          0,
        )}% 女性：${content_meta_data.gender.female_percent.toFixed(0)}%`,
      });
    }
    return result;
  }, [content_meta_data]);
  // カメラアイコン用のpropsデータ
  const gradient_option = useMemo(() => {
    let result: CameraMarkerIconProps['gradient_option'] = undefined;
    // 男女どちらも0の場合は青と赤のエフェクトを付けない
    if (content_meta_data?.gender?.male_percent === 0 && content_meta_data?.gender?.female_percent === 0) return result;
    if (typeof content_meta_data?.gender?.male_percent === 'number') {
      result = {
        left_percent: content_meta_data.gender.male_percent,
      };
    }
    return result;
  }, [content_meta_data?.gender?.male_percent, content_meta_data?.gender?.female_percent]);
  const circle_size = useMemo(() => {
    return getCameraCircleSize(content_meta_data?.human_traffic, zoom_level);
  }, [content_meta_data?.human_traffic, zoom_level]);

  useEffect(() => {
    loadCountdataList();
  }, [loadCountdataList]);

  return useMemo(() => {
    return { content_meta_data, tooltip_descriptions, gradient_option, circle_size };
  }, [content_meta_data, tooltip_descriptions, gradient_option, circle_size]);
}
