import { Space, RadioChangeEvent, Col, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Unit, display_unit_select_items } from '@shared/models/Unit';
import { RadioInput } from '@shared/components/RadioInput';
import { DynamicDatePicker } from 'components/DynamicDatePicker';
import { HourTimeSelect } from 'components/HourTimeSelect';
import { Period } from '@shared/models/Period';
import { TitleTaggedComponent } from '@shared/components/TitleTaggedComponent';

export interface PeriodSetting {
  unit: Unit;
  period: Period;
  date?: Dayjs;
  times?: [number, number];
}

export interface PeriodSelectProps {
  form: PeriodSetting;
  updateForm(period_setting: PeriodSetting): void;
}
export function PeriodSelect({ form, updateForm }: PeriodSelectProps) {
  const onDisplayPeriodChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    if (value === 'today') {
      updateForm({
        ...form,
        period: value,
        date: dayjs(),
      });
    } else if (value === 'period') {
      updateForm({
        ...form,
        period: value,
      });
    }
  };

  const updateTimes = (times: [number, number]) => {
    updateForm({ ...form, times });
  };

  return (
    <Space size={[0, 16]} direction='vertical' style={{ width: '100%' }}>
      <Space size={[0, 8]} direction='vertical' style={{ width: '100%' }}>
        <TitleTaggedComponent title='表示単位' direction='vertical'>
          <RadioInput
            items={display_unit_select_items}
            value={form.unit}
            onChange={(e) => updateForm({ ...form, unit: e.target.value })}
          />
        </TitleTaggedComponent>
      </Space>
      <Space size={[0, 8]} direction='vertical' style={{ width: '100%' }}>
        <TitleTaggedComponent title=' 表示期間' direction='vertical'>
          <RadioInput
            items={[
              { value: 'today', label: '今日' },
              { value: 'period', label: '期間指定' },
            ]}
            value={form.period}
            onChange={onDisplayPeriodChange}
          />
        </TitleTaggedComponent>
        {form.period === 'period' && (
          <Row justify='space-between'>
            <Col span={10}>
              <DynamicDatePicker
                date={form.date}
                unit={form.unit}
                updateDate={(value) => updateForm({ ...form, date: value })}
              />
            </Col>
            <Col span={10}>
              {form.unit === 'hour' && (
                <HourTimeSelect value={form.times} updateTimes={updateTimes} style={{ width: '100%' }} />
              )}
            </Col>
          </Row>
        )}
      </Space>
    </Space>
  );
}
