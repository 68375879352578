import { Button, Drawer, Space } from 'antd';
import { useBoolean } from '@shared/hooks/useBoolean/useBoolean';
import { MenuOutlined } from '@ant-design/icons';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { hasHumanTraffic } from '@shared/models/Content';
import { SearchEngine } from 'pages/urban/top/TopPage/SearchEngine';
import { Camera } from 'api/cameras';
import { PeriodSettingEngine } from 'components/PeriodSettingEngine';
import { ContentSettingEngine } from 'components/ContentSettingEngine';
import { AgeSettingEngine } from 'components/AgeSettingEngine';

export interface UserMenuDrawerProps {
  searched_camera: Camera | null;
  setSearchedCamera(camera: Camera | null): void;
  period_setting: PeriodSetting;
  customUpdatePeriodSetting(form: PeriodSetting): void;
  content_setting: ContentSetting;
  customUpdateContentSetting(form: ContentSetting): void;
  age_setting: AgeSetting;
  onAgeSettingModalFinish(form: AgeSetting): void;
}

export function UserMenuDrawer(params: UserMenuDrawerProps) {
  const { bool: open, onOpen, onClose } = useBoolean();

  return (
    <Space>
      <Button shape='circle' icon={<MenuOutlined />} style={{ zIndex: 2 }} onClick={onOpen} size='large' />
      <Drawer
        placement='left'
        width={220}
        onClose={onClose}
        open={open}
        headerStyle={{ padding: '10px', height: '45px' }}
      >
        <Space direction='vertical' size={16} style={{ width: '100%' }}>
          <SearchEngine setSearchedCamera={params.setSearchedCamera} searched_camera={params.searched_camera} />
          <PeriodSettingEngine
            form={params.period_setting}
            updateForm={params.customUpdatePeriodSetting}
            input_style={{ maxWidth: 200 }}
          />
          <ContentSettingEngine updateForm={params.customUpdateContentSetting} form={params.content_setting} />
          {hasHumanTraffic(params.content_setting.contents) && (
            <AgeSettingEngine updateForm={params.onAgeSettingModalFinish} form={params.age_setting} />
          )}
        </Space>
      </Drawer>
    </Space>
  );
}
